import axios from '../axios'

const sendlinkSurvey = (respondenId, access_token) => new Promise((resolve, reject) => {
  axios({
    method: 'POST',
    url: '/survey/link/' + respondenId,
    headers: {
      access_token
    }
  }).then(({ data }) => {
    resolve(data)
  }).catch(err => {
    reject(err)
  })
})

export default sendlinkSurvey