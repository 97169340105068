import Styles from './styles.module.scss'
import React, { useEffect, useContext, useState, useMemo, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import RespondenAction from '../../store/redux/responden/action'
import AuthContext from '../../store/AuthContext'
import { PermataWrapper, ButtonLoader, RefreshButton } from '../../components'
import { TextField, Modal, Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, Paper, Pagination, IconButton, Dialog, DialogTitle, 
  DialogActions, Button, InputAdornment, Tooltip, Autocomplete, Alert, DialogContent, DialogContentText, Snackbar } from '@mui/material'
import { Delete as DeleteIcon, WhatsApp, Add as AddIcon } from '@mui/icons-material'
import registerResponden from '../../async/registerResponden'
import deleteResponden from '../../async/deleteResponden'
import sendlinkSurvey from '../../async/sendLinkSurvey'
import Chart from 'react-apexcharts'
import COMPANY_DATA from '../../assets/data/company_data.json'
import Master from './Master'
import axios from '../../axios'

export function SentChart (props) {
  const { ammount, total, setSelected } = props
  const [showTotal, setShowTotal] = useState(true)

  const series = useMemo(() => {
    return [ammount, total-ammount]
  }, [ammount, total])
  const options = useMemo(() => {
    return {
      labels: ['Link sent', 'Link not yet sent'],
      legend: {
        show: false
      },
      colors: ['#00D1FF', '#C8C8C8'],
      chart: {
        events: {
          dataPointSelection: (event, chartContext, config) => config.dataPointIndex === 0 ? setSelected(prev => prev === 'Link sent' ? '' : 'Link sent') : setSelected(prev => prev === 'Link not yet sent' ? '' : 'Link not yet sent')
        }
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: showTotal,
                showAlways: true
              }
            }
          }
        }
      }
    }
  }, [showTotal])
  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth < 440) {
        setShowTotal(false)
      } else if (window.innerWidth >= 440) {
        setShowTotal(true)
      }
    })
  }, [])
  return (
    <Chart type="donut" options={ options } series={ series } height={ 250 } width={ 250 }/>
  )
}

export function SubmitChart (props) {
  const { ammount, total, setSelected } = props
  const [showTotal, setShowTotal] = useState(true)
  const series = useMemo(() => {
    return [ammount, total-ammount]
  }, [ammount, total])
  const options = useMemo(() => {
    return {
      labels: ['Submitted', 'Not submitted'],
      legend: {
        show: false
      },
      colors: ['#00D1FF', '#C8C8C8'],
      chart: {
        events: {
          dataPointSelection: (event, chartContext, config) => config.dataPointIndex === 0 ? setSelected(prev => prev === 'Submitted' ? '' : 'Submitted') : setSelected(prev => prev === 'Not submitted' ? '' : 'Not submitted')
        }
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: showTotal,
                showAlways: true
              }
            }
          }
        }
      }
    }
  }, [showTotal])
  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth < 440) {
        setShowTotal(false)
      } else if (window.innerWidth >= 440) {
        setShowTotal(true)
      }
    })
  }, [])
  return (
    <Chart type="donut" options={ options } series={ series } height={ 250 } width={ 250 }/>
  )
}

export default function Dashboard() {
  const dispatch = useDispatch()
  const { access_token } = useContext(AuthContext)
  const [registerModal, setRegisterModal] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [submitChartSelect, setSubmitChartSelect] = useState('')
  const [sentChartSelect, setSentChartSelect] = useState('')
  const [authMaster, setAuthMaster] = useState(false)
  const [showWA, setShowWA] = useState(null)
  const [showLinkSent, setShowLinkSent] = useState(false)
  const [respondenData, setRespondenData] = useState({
    Name: '',
    Company: '',
    Position: '',
    Email: '',
    PhoneNumber: ''
  })
  const [deleteDialog, setDeleteDialog] = useState(false)
  const { list } = useSelector(({ responden }) => responden)
  const [tablePage, setTablePage] = useState(1)
  const [searchInclude, setSearchInclude] = useState('')
  const [loading, setLoading] = useState(0)
  const [registerErrorMessage, setRegisterErrorMessage] = useState('')
  const [pageLoading, setPageLoading] = useState(true)
  const [companyOptions, setCompanyOptions] = useState([])

  useEffect(() => {

    (async () => {
      try {
        const { data: { response: { companies } } } = await axios({
          url: '/company',
          method: 'GET'
        })

        setCompanyOptions(companies)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  useLayoutEffect(() => {
    let isMaster = sessionStorage.getItem('Master')
    if (isMaster) setAuthMaster(true)
    else setAuthMaster(false)
    setPageLoading(false)
  }, [list])

  const handleTextFieldEnter = (e) => {
    if (e.key === 'Enter') {
      setSearchInclude(searchInput)
      setTablePage(1)
      setSearchInput('')
    }
  }

  const handleResetRespondenData = () => {
    setRespondenData({
      Name: '',
      Company: '',
      Position: '',
      Email: '',
      PhoneNumber: ''
    })
  }

  const handleRegisterResponden = async (e) => {
    try {
      e.preventDefault()
      setLoading(1)
      if (!companyOptions.find(el => el.name.toUpperCase() === respondenData.Company)) throw { response: { data: { message: 'Company unknown' } } }
      await registerResponden({ ...respondenData, PhoneNumber: `62${ respondenData.PhoneNumber }` }, access_token)
      dispatch(RespondenAction.fetch(access_token))
      setRegisterModal(false)
      handleResetRespondenData()
      setLoading(0)
    } catch (err) {
      console.log(err.response.data.message)
      if (!registerErrorMessage) {
        setRegisterErrorMessage(err.response.data.message)
        setTimeout(() => {
          setRegisterErrorMessage('')
        }, 2000)
      }
      setLoading(0)
    }
  }

  const handleChangeRespondenData = (e) => {
    if (e.target.name === 'PhoneNumber') {
      setRespondenData(prev => {
        if (prev.PhoneNumber === '') {
          if (e.target.value === '0' || e.target.value === '+' || e.target.value === '6') return { ...prev, [e.target.name]: '' }
          else return { ...prev, [e.target.name]: e.target.value.replace(/[^0-9]/g, '') }
        } else return { ...prev, [e.target.name]: e.target.value.replace(/[^0-9]/g, '') }
      })
    } else setRespondenData(prev => ({ ...prev, [e.target.name]: e.target.value }))    
  }

  const handleDeleteResponden = async () => {
    try {
      setLoading(1)
      await deleteResponden(deleteDialog._id, access_token)
      dispatch(RespondenAction.fetch(access_token))
      setDeleteDialog(false)
      setLoading(0)
    } catch(err) {
      console.log(err, 'handleDeleteResponden')
      setLoading(0)
    }
  }

  const handleSendSurveyLink = async (id) => {
    try {
      setLoading(1)
      await sendlinkSurvey(id, access_token)
      dispatch(RespondenAction.fetch(access_token))
      setLoading(0)
      setShowLinkSent(true)
    } catch(err) {
      console.log(err, 'handleSendSurveyLink error')
    }
  }

  const handleChangeRespondenCompany = (e, value) => {
    if (value?.company) {
      handleChangeRespondenData({
        target: {
          name: 'Company',
          value: value.company
        }
      })
    } else {
      handleChangeRespondenData({
        target: {
          name: 'Company',
          value: ''
        }
      })
    }
  }

  const onSendWA = () => {
    handleSendSurveyLink(showWA)
    setShowWA(false)
  }

  if (pageLoading) {
    return (
      <PermataWrapper>

      </PermataWrapper>
    )
  }

  if (authMaster) {
    return (
      <Master/>
    )
  }

  return (
    <PermataWrapper>
      <Snackbar open={showLinkSent} autoHideDuration={6000} onClose={() => setShowLinkSent(false)}>
        <Alert onClose={() => setShowLinkSent(false)} severity="success" sx={{ width: '100%' }}>
          The Invitation Link Has Been Sent!
        </Alert>
      </Snackbar>
      <Dialog onClose={ () => setDeleteDialog(false) } open={ deleteDialog ? true : deleteDialog }>
        <DialogTitle>
          Are you sure you want to delete ? this action is irreversible.
        </DialogTitle>
        <DialogActions>
          <Button onClick={ handleDeleteResponden } variant="outlined" color="error">DELETE</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showWA}
        onClose={() => setShowWA(null) }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The person you're trying to send WhatsApp link to might've already received the link you have sent earlier.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={ onSendWA } autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Modal className={ Styles.MuiModal } open={ registerModal } onClose={ () => setRegisterModal(false) }>
        <div className={ Styles.BoxRegister }>
          <div className={ Styles.BoxTitle }>
            <span>Register Responden</span>
          </div>
          <form onSubmit={ handleRegisterResponden } className={ Styles.BoxContent }>
            <div className={ Styles.FormControl }>
              <TextField onChange={ handleChangeRespondenData } value={ respondenData.Name } name="Name" fullWidth variant="outlined" required label="Name"/>
            </div>
            <div className={ Styles.FormControl }>
              <Autocomplete isOptionEqualToValue={ option => option.name } value={ respondenData.Company } onChange={ handleChangeRespondenCompany } disablePortal renderInput={ (params) => <TextField { ...params } name="Company" fullWidth variant="outlined" required label="Company"/> } options={ companyOptions.map(el => ({ ...el, label: el.name.toUpperCase(), company: el.name.toUpperCase() })) }/>
            </div>
            <div className={ Styles.FormControl }>
              <TextField onChange={ handleChangeRespondenData } value={ respondenData.Position } name="Position" fullWidth variant="outlined" required label="Position"/>
            </div>
            <div className={ Styles.FormControl }>
              <TextField onChange={ handleChangeRespondenData } value={ respondenData.Email } name="Email" fullWidth variant="outlined" required label="Email"/>
            </div>
            <div className={ Styles.FormControl }>
              <TextField onChange={ handleChangeRespondenData } InputProps={ { startAdornment: <InputAdornment position="start">+62</InputAdornment>  } } value={ respondenData.PhoneNumber } name="PhoneNumber" fullWidth variant="outlined" required label="Phone Number"/>
            </div>
            <div className={ Styles.SubmitControl }>
              <ButtonLoader loading={ loading } type="submit">
                <span>Register Responden</span>
              </ButtonLoader>
            </div>
          </form>
          <div className={ `${ Styles.ErrorWrapper } ${ !registerErrorMessage && Styles.None }` }>
            <Alert severity="error" onClose={ () => setRegisterErrorMessage('') }>{ registerErrorMessage }</Alert>
          </div>
        </div>
        
      </Modal>
      <div className={ Styles.Container }>
        <div className={ Styles.ChartContainer }>
          <div className={ Styles.ChartCard }>
            <SubmitChart setSelected={ setSubmitChartSelect } ammount={ list.filter(el => el.Status === 'Survey Submitted').length } total={ list.filter(el => el.Status !== 'Registered').length }/>
            <div>
              { submitChartSelect ? (
                <div>
                  <span>Percentage of them who had <span className={ submitChartSelect === 'Submitted' ? Styles.LightBlue : Styles.LightGrey }>{ submitChartSelect.toLowerCase() }: </span></span>
                  <span>{ (submitChartSelect === 'Submitted' ? list.filter(el => el.Status === 'Survey Submitted').length : (list.filter(el => el.Status !== 'Registered').length - list.filter(el => el.Status === 'Survey Submitted').length) ) } ({ (submitChartSelect === 'Submitted' ? list.filter(el => el.Status === 'Survey Submitted').length : (list.filter(el => el.Status !== 'Registered').length - list.filter(el => el.Status === 'Survey Submitted').length))/list.filter(el => el.Status !== 'Registered').length*100 }%)</span>
                </div>
              ) : (
                <span>Percentage of them who had submitted the survey compared to them who had received the survey link</span>
              ) }
            </div>
          </div>
          <div className={ Styles.ChartCard }>
            <SentChart setSelected={ setSentChartSelect } ammount={ list.filter(el => el.Status === 'Link Sent' || el.Status === 'Survey Submitted').length } total={ list.length }/>
            <div>
              { sentChartSelect ? (
                <div>
                  <span><span className={ sentChartSelect === 'Link sent' ? Styles.LightBlue : Styles.LightGrey }>{ sentChartSelect }</span> percentage: </span>
                  <span>{ (sentChartSelect === 'Link sent' ? list.filter(el => el.Status === 'Survey Submitted' || el.Status === 'Link Sent').length : (list.length - list.filter(el => el.Status === 'Survey Submitted' || el.Status === 'Link Sent').length) ) } ({ (sentChartSelect === 'Link sent' ? list.filter(el => el.Status === 'Survey Submitted' || el.Status === 'Link Sent').length : (list.length - list.filter(el => el.Status === 'Survey Submitted' || el.Status === 'Link Sent').length))/list.length*100 }%)</span>
                </div>
              ) : (
                <span>Percentage of them who had received the survey link compared to all the responden</span>
              ) }
            </div>
          </div>
        </div>
        <div className={ Styles.ContentCard }>
          <div className={ Styles.ContentHelperWrapper }>
            <div className={ Styles.ContentSearchWrapper }>
              <TextField value={ searchInput } onChange={ (e) => setSearchInput(e.target.value) } onKeyDown={ handleTextFieldEnter } placeholder="Search..." fullWidth/>
            </div>
            <div className={ Styles.VerticalLine }/>
            <div className={ Styles.ContentButtonWrapper }>
              <Button variant="outlined" startIcon={ <AddIcon/> } onClick={ () => setRegisterModal(true) } className={ Styles.RegRespondenButton }>
                <span>REGISTER RESPONDEN</span>
              </Button>
            </div>
            <div className={ Styles.VerticalLine }/>
            <div className={ Styles.RefreshButtonWrapper }>
              <RefreshButton onClick={ () => dispatch(RespondenAction.fetch(access_token))}/>
            </div>
          </div>
          <div className={ Styles.ContentTable }>
            <TableContainer className={ Styles.Table } component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell style={ { width: 100 } } align="right">Name</TableCell>
                    <TableCell align="right">Company</TableCell>
                    <TableCell align="right">Position</TableCell>
                    <TableCell align="right">Email</TableCell>
                    <TableCell align="right">Phone Number</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { list.filter(el => el.Company.toLowerCase().includes(searchInclude.toLowerCase()) || el.Name.toLowerCase().includes(searchInclude.toLowerCase())).sort((a, b) => { 
                    if (a.Name > b.Name) return 1
                    if (b.Name > a.Name) return -1
                    return 0
                   }).slice(0+(5*(tablePage-1)), 5+(5*(tablePage-1))).map((el, ind) => (
                    <TableRow style={ { height: 150 } } key={ ind }>
                      <TableCell>{ ind+1+(5*(tablePage-1)) }</TableCell>
                      <TableCell align="right">{ el.Name }</TableCell>
                      <TableCell align="right">{ el.Company }</TableCell>
                      <TableCell align="right">{ el.Position }</TableCell>
                      <TableCell align="right">{ el.Email }</TableCell>
                      <TableCell align="right">{ el.PhoneNumber }</TableCell>
                      <TableCell align="right">{ el.Status }</TableCell>

                      <TableCell align="right">
                        {/* { el.Status === 'Registered' ? (
                          <Tooltip title="Send Link"><IconButton onClick={ () => handleSendSurveyLink(el._id) }><WhatsApp style={ { color: '#25d366' } }/></IconButton></Tooltip>
                        ) : (
                          <IconButton disabled={ el.Status === 'Survey Submitted' } onClick={ () => handleSendSurveyLink(el._id) }><WhatsApp style={ { color: el.Status === 'Survey Submitted' ? '#ece5dd' : '#25d366' } }/></IconButton>
                        ) } */}
                        <IconButton onClick={ () => el.Status === 'Registered' ? handleSendSurveyLink(el._id) : setShowWA(el._id) }><WhatsApp style={ { color: '#25d366' } }/></IconButton>
                        <Tooltip title="Delete"><IconButton onClick={ () => setDeleteDialog(el) }><DeleteIcon/></IconButton></Tooltip></TableCell>
                    </TableRow>
                  )) } 
                </TableBody>
              </Table>
            </TableContainer>
            <div className={ Styles.TablePagination }>
              <Pagination page={ tablePage } onChange={ (e, val) => setTablePage(val) } count={ Math.ceil(list.filter(el => el.Name.toLowerCase().includes(searchInclude.toLowerCase())).length/5) }/>
            </div>
          </div>
        </div>
      </div>
    </PermataWrapper>
  )
}
