import axios from '../axios'

const deleteResponden = (idResponden, access_token) => new Promise((resolve, reject) => {
  axios({
    url: '/responden/' + idResponden,
    method: 'DELETE',
    headers: {
      access_token
    }
  }).then(({ data }) => {
    resolve(data)
  }).catch(err => {
    reject(err)
  })
})

export default deleteResponden