import Styles from './styles.module.scss'
import React, { useState, useMemo, useEffect, useCallback } from 'react'
import REFRESH_BUTTON_ANIM from '../../assets/lottie/refresh_button_anim.json'
import Lottie from 'react-lottie'
import { IconButton } from '@mui/material'

export default function RefreshButton (props) {
  const { onClick } = props
  const [isStopped, setIsStopped] = useState(true)
  const [disabled, setDisabled] = useState(false)
  const animOptions = useMemo(() => {
    return {
      loop: false,
      autoplay: true, 
      animationData: REFRESH_BUTTON_ANIM
    }
  }, [])

  useEffect(() => {
    upgradedOnClick()
  }, [])

  const upgradedOnClick = useCallback(() => {
    if (!disabled) {
      setDisabled(true)
      setIsStopped(false)
      onClick()
      setTimeout(() => {
        setIsStopped(true)
        setDisabled(false)
      }, 1500)
    }
  }, [])

  // return (
  //   <IconButton className={ Styles.RefreshButton } onClick={ upgradedOnClick }>
  //     <Lottie animationData={ REFRESH_BUTTON_ANIM } lottieRef={ btnRef } loop={ true } autoplay={ true } style={ { height: 40 } }/>
  //   </IconButton>
  // )
  return (
    <IconButton disabled={ disabled } className={ Styles.RefreshButton } onClick={ upgradedOnClick }>
      <Lottie isStopped={ isStopped } isClickToPauseDisabled options={ animOptions } style={ { height: 40 } }/>
    </IconButton>
  )
}