import Styles from './styles.module.scss'
import React, { useState, useContext, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AuthContext from '../../store/AuthContext'
import { PermataWrapper, RefreshButton } from '../../components'
import { TextField, Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, Paper, Pagination, Button, Modal, MenuItem } from '@mui/material'
import ReferrerAction from '../../store/redux/referrer/action'
import { Rule } from '@mui/icons-material'

export default function ReferrerPage() {
  const dispatch = useDispatch()
  const [searchInput, setSearchInput] = useState('')
  const [searchInclude, setSearchInclude] = useState('')
  const [filterModal, setFilterModal] = useState(false)
  const [filterBy, setFilterBy] = useState('Name')
  const [filterArea, setFilterArea] = useState('')
  const [filterRegion, setFilterRegion] = useState('')
  const [sortType, setSortType] = useState('ASC')
  const [filterBenchmark, setFilterBenchmark] = useState(0)
  const [filterOp, setFilterOp] = useState('>=')
  const [tablePage, setTablePage] = useState(1)
  const [areaList, setAreaList] = useState([])
  const [regionList, setRegionList] = useState([])
  const [referrerList, setReferrerList] = useState([])
  const { access_token, _id } = useContext(AuthContext)
  const { list } = useSelector(({ referrer }) => referrer)

  useEffect(() => {
    let newArea = []
    let newRegion = []
    list.forEach(el => {
      if (!newArea.find((elm) => elm === el.Area)) newArea.push(el.Area)
      if (!newRegion.find((elm) => elm === el.Region)) newRegion.push(el.Region)
    })
    setAreaList(newArea.filter(el => el))
    setRegionList(newRegion.filter(el => el))
    setReferrerList(list.sort((a, b) => { 
      if (a.Name > b.Name) return 1
      if (b.Name > a.Name) return -1
      return 0
     }))
  }, [list])

  const handleTextFieldEnter = (e) => {
    if (e.key === 'Enter') {
      setSearchInclude(searchInput)
      setTablePage(1)
      setSearchInput('')
    }
  }

  const handleFilter = () => {
    switch (filterBy) {
      case 'Area':
        if (sortType === 'ASC') {
          setReferrerList(list.sort((a, b) => { 
            if (a.Name > b.Name) return 1
            if (b.Name > a.Name) return -1
            return 0
          }).sort((a, b) => { 
            if (a.Area > b.Area) return 1
            if (b.Area > a.Area) return -1
            return 0
          }))
        } else if (sortType === 'DSC') {
          setReferrerList(list.sort((a, b) => { 
            if (a.Name > b.Name) return 1
            if (b.Name > a.Name) return -1
            return 0
          }).sort((b, a) => { 
            if (a.Area > b.Area) return 1
            if (b.Area > a.Area) return -1
            return 0
          }))
        } else {
          setReferrerList(list.filter(el => el.Area === filterArea).sort((b, a) => { 
            if (a.Area > b.Area) return 1
            if (b.Area > a.Area) return -1
            return 0
          }).sort((a, b) => { 
            if (a.Name > b.Name) return 1
            if (b.Name > a.Name) return -1
            return 0
          }))
        }
        break;
      case 'Region':
        if (sortType === 'ASC') {
          setReferrerList(list.sort((a, b) => { 
            if (a.Name > b.Name) return 1
            if (b.Name > a.Name) return -1
            return 0
          }).sort((a, b) => { 
            if (a.Region > b.Region) return 1
            if (b.Region > a.Region) return -1
            return 0
          }))
        } else if (sortType === 'DSC') {
          setReferrerList(list.sort((a, b) => { 
            if (a.Name > b.Name) return 1
            if (b.Name > a.Name) return -1
            return 0
          }).sort((b, a) => { 
            if (a.Region > b.Region) return 1
            if (b.Region > a.Region) return -1
            return 0
          }))
        } else {
          setReferrerList(list.filter(el => el.Region === filterRegion).sort((b, a) => { 
            if (a.Region > b.Region) return 1
            if (b.Region > a.Region) return -1
            return 0
          }).sort((a, b) => { 
            if (a.Name > b.Name) return 1
            if (b.Name > a.Name) return -1
            return 0
          }))
        }
        break;
      case 'TotalResponden':
        if (sortType === 'ASC') {
          setReferrerList(list.sort((a, b) => { 
            if (a.Name > b.Name) return 1
            if (b.Name > a.Name) return -1
            return 0
          }).sort((a, b) => { 
            if (a.UkSpv > b.UkSpv) return 1
            if (b.UkSpv > a.UkSpv) return -1
            return 0
          }))
        } else if (sortType === 'DSC') {
          setReferrerList(list.sort((a, b) => { 
            if (a.Name > b.Name) return 1
            if (b.Name > a.Name) return -1
            return 0
          }).sort((b, a) => { 
            if (a.UkSpv > b.UkSpv) return 1
            if (b.UkSpv > a.UkSpv) return -1
            return 0
          }))
        } else {
          if (filterOp === '>=') {
            setReferrerList(list.sort((a, b) => { 
              if (a.Name > b.Name) return 1
              if (b.Name > a.Name) return -1
              return 0
            }).filter(el => el.UkSpv >= filterBenchmark))
          } else if (filterOp === '<=') {
            setReferrerList(list.sort((a, b) => { 
              if (a.Name > b.Name) return 1
              if (b.Name > a.Name) return -1
              return 0
            }).filter(el => el.UkSpv <= filterBenchmark))
          } else {
            setReferrerList(list.sort((a, b) => { 
              if (a.Name > b.Name) return 1
              if (b.Name > a.Name) return -1
              return 0
            }).filter(el => el.UkSpv === filterBenchmark))
          }
        }
        break;
      default:
        if (sortType === 'ASC') {
          setReferrerList(list.sort((a, b) => { 
            if (a.Name > b.Name) return 1
            if (b.Name > a.Name) return -1
            return 0
          }))
        } else {
          setReferrerList(list.sort((b, a) => { 
            if (a.Name > b.Name) return 1
            if (b.Name > a.Name) return -1
            return 0
          }))
        }
    }
    setTablePage(1)
    setFilterModal(false)
  }

  const handleReset = () => {
    setFilterBy('Name')
    setFilterArea('')
    setFilterRegion('')
    setSortType('ASC')
    setFilterBenchmark(0)
    setFilterOp('>=')
    setFilterModal(false)
    setTablePage(1)
    setReferrerList(list.sort((a, b) => { 
      if (a.Name > b.Name) return 1
      if (b.Name > a.Name) return -1
      return 0
    }))
  }

  return (
    <PermataWrapper>
      <Modal open={ filterModal } onClose={ () => setFilterModal(false) }>
        <div className={ Styles.BoxFilter }>
          <form onSubmit={ handleFilter } className={ Styles.FilterHelperWrapper }>
            <div className={ Styles.FilterWrapper }>
              <div className={ Styles.FilterByWrapper }>
                <TextField value={ filterBy } onChange={ (e) => {
                  setFilterBy(e.target.value)
                  setSortType('ASC')
                  } } select label="Filter by" fullWidth>
                  <MenuItem value={ 'Name' }>Name</MenuItem>
                  <MenuItem value={ 'Area' }>Area</MenuItem>
                  <MenuItem value={ 'Region' }>Region</MenuItem>
                  <MenuItem value={ 'TotalResponden' }>Jumlah Responden</MenuItem>
                </TextField>
              </div>
              <div className={ Styles.VerticalLine}/>
              <div className={ `${ Styles.FilterSortWrapper }` }>
                <TextField value={ sortType } onChange={ (e) => setSortType(e.target.value) } select label="Sort type" fullWidth>
                  <MenuItem value={ 'ASC' }>Ascend</MenuItem>
                  <MenuItem value={ 'DSC' }>Descend</MenuItem>
                  { filterBy !== 'Name' && (
                    <MenuItem value={ 'DEP' }>Dependant</MenuItem>
                  ) }
                </TextField>
              </div>
              <div className={ `${ filterBy === 'TotalResponden' && sortType === 'DEP' && Styles.VerticalLine }` }/>
              { filterBy === 'TotalResponden' && sortType === 'DEP' && (
              <div className={ `${ Styles.FilterExtraWrapper }` }>
                <TextField style={ { flex: 1 } } value={ filterOp } onChange={ (e) => setFilterOp(e.target.value) } select label="Op" fullWidth>
                  <MenuItem value={ '>=' }>&#8805;</MenuItem>
                  <MenuItem value={ '===' }>&#61;</MenuItem>
                  <MenuItem value={ '<=' }>&#8804;</MenuItem>
                </TextField>
                <TextField style={ { flex: 3 } } type="number" value={ filterBenchmark } onChange={ (e) => setFilterBenchmark(e.target.value) }/>
              </div>
              ) }
              <div className={ `${ filterBy === 'Area' && sortType === 'DEP' && Styles.VerticalLine }` }/>
              { filterBy === 'Area' && sortType === 'DEP' && (
              <div className={ `${ Styles.FilterExtraWrapper }` }>
                <TextField value={ filterArea } onChange={ (e) => setFilterArea(e.target.value) } select label="Area" fullWidth>
                  { areaList.map(el => (
                    <MenuItem key={ el } value={ el }>{ el }</MenuItem>
                  )) }
                </TextField>
              </div>
              ) }
              <div className={ `${ filterBy === 'Region' && sortType === 'DEP' && Styles.VerticalLine }` }/>
              { filterBy === 'Region' && sortType === 'DEP' && (
              <div className={ `${ Styles.FilterExtraWrapper }` }>
                <TextField value={ filterRegion } onChange={ (e) => setFilterRegion(e.target.value) } select label="Region" fullWidth>
                  { regionList.map(el => (
                    <MenuItem key={ el } value={ el }>{ el }</MenuItem>
                  )) }
                </TextField>
              </div>
              ) }
            </div>
            <div className={ Styles.FilterSubmitWrapper }>
              <Button onClick={ handleReset } color="error">
                <span>Reset</span>
              </Button>
              <Button type="submit" style={ { marginRight: 20 } }>
                <span>Set Filter</span>
              </Button>
            </div>
          </form>
        </div>
      </Modal>
      <div className={ Styles.Container }>
        <div className={ Styles.ContentCard }>
          <div className={ Styles.ContentHelperWrapper }>
            <div className={ Styles.ContentSearchWrapper }>
              <TextField value={ searchInput } onChange={ (e) => setSearchInput(e.target.value) } onKeyDown={ handleTextFieldEnter } placeholder="Search..." fullWidth/>
            </div>
            <div className={ Styles.VerticalLine }/>
            <div className={ Styles.ContentButtonWrapper }>
              <Button variant="outlined" startIcon={ <Rule/> } onClick={ () => setFilterModal(true) } className={ Styles.FilterButton }>
                <span>FILTER</span>
              </Button>
            </div>
            <div className={ Styles.VerticalLine }/>
            <div className={ Styles.RefreshButtonWrapper }>
              <RefreshButton onClick={ () => dispatch(ReferrerAction.fetch(access_token))}/>
            </div>
          </div>
          <div className={ Styles.ContentTable }>
            <TableContainer className={ Styles.Table } component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell style={ { width: 100 } } align="right">NRK</TableCell>
                    <TableCell style={ { width: 100 } } align="right">Nama</TableCell>
                    <TableCell align="right">Phone Number</TableCell>
                    <TableCell align="right">Area</TableCell>
                    <TableCell align="right">Region</TableCell>
                    <TableCell align="right">Jumlah Responden</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { referrerList.filter(el => el.Name.toLowerCase().includes(searchInclude.toLowerCase()) && el.Name !== 'MASTER').slice(0+(5*(tablePage-1)), 5+(5*(tablePage-1))).map((el, ind) => (
                    <TableRow style={ { height: 150, backgroundColor: _id === el._id ? '#eee' : '#fff' } } key={ ind }>
                      <TableCell>{ ind+1+(5*(tablePage-1)) }</TableCell>
                      <TableCell align="right">{ el.Nrk }</TableCell>
                      <TableCell align="right">{ el.Name }</TableCell>
                      <TableCell align="right">{ el.PhoneNumber }</TableCell>
                      <TableCell align="right">{ el.Area }</TableCell>
                      <TableCell align="right">{ el.Region}</TableCell>
                      <TableCell align="right">{ el.UkSpv }</TableCell>
                    </TableRow>
                  )) } 
                </TableBody>
              </Table>
            </TableContainer>
            <div className={ Styles.TablePagination }>
              <Pagination page={ tablePage } onChange={ (e, val) => setTablePage(val) } count={ Math.ceil(referrerList.filter(el => el.Name.toLowerCase().includes(searchInclude.toLowerCase()) && el.Name !== 'MASTER').length/5) }/>
            </div>
          </div>
        </div>
      </div>
    </PermataWrapper>
  )
}
