import axios from '../../../axios'

export default class ReferrerAction {
  static fetch = (access_token) => async (dispatch, getState) => {
    try {
      const { data: { data } } = await axios({
        method: 'GET',
        url: '/referrer',
        headers: {
          access_token
        }
      })
      dispatch({
        type: 'SET_REFERRER',
        payload: data
      })

    } catch (err) {
      console.log(err, 'fetch referrer error')
    }
  }
}