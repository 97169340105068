import Styles from '../styles.module.scss'
import React, { useState, useEffect, useContext, useMemo } from 'react'
import { PermataWrapper, ExportCSV } from '../../../components'
import { Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, Paper, Pagination, Rating, Box,
  Tabs, Tab } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import SurveyAction from '../../../store/redux/survey/action'
import AuthContext from '../../../store/AuthContext'
import Chart from 'react-apexcharts'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function ReferrerChart (props) {
  const { referrer, responden, setSelected } = props
  const [showTotal, setShowTotal] = useState(true)
  const series = useMemo(() => {
    return [referrer, responden]
  }, [referrer, responden])
  const options = useMemo(() => {
    return {
      labels: ['Referrer', 'Responden'],
      legend: {
        show: false
      },
      colors: ['#00D1FF', '#C8C8C8'],
      chart: {
        events: {
          dataPointSelection: (event, chartContext, config) => config.dataPointIndex === 0 ? 
            setSelected(prev => {
              if (prev === 'Referrer') setShowTotal(true)
              else setShowTotal(false)
              return prev === 'Referrer' ? '' : 'Referrer'
            }) : 
            setSelected(prev => {
              if (prev === 'Responden') setShowTotal(true)
              else setShowTotal(false)
              return prev === 'Responden' ? '' : 'Responden'
            })
        }
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: showTotal,
                showAlways: true
              }
            }
          }
        }
      }
    }
  }, [showTotal])

  return (
    <Chart type="donut" options={ options } series={ series } height={ 250 } width={ 250 }/>
  )
}

function TkoChart (props) {
  const { submitted, notsubmitted, setSelected } = props
  const [showTotal, setShowTotal] = useState(true)
  const series = useMemo(() => {
    return [submitted, notsubmitted]
  }, [submitted, notsubmitted])
  const options = useMemo(() => {
    return {
      labels: ['Submitted TKO', 'Not Submitted TKO'],
      legend: {
        show: false
      },
      colors: ['#00D1FF', '#C8C8C8'],
      chart: {
        events: {
          dataPointSelection: (event, chartContext, config) => config.dataPointIndex === 0 ? 
            setSelected(prev => {
              if (prev === 'Submitted') setShowTotal(true)
              else setShowTotal(false)
              return prev === 'Submitted' ? '' : 'Submitted'
            }) : 
            setSelected(prev => {
              if (prev === 'Not Submitted') setShowTotal(true)
              else setShowTotal(false)
              return prev === 'Not Submitted' ? '' : 'Not Submitted'
            })
        }
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: showTotal,
                showAlways: true
              }
            }
          }
        }
      }
    }
  }, [showTotal])

  return (
    <Chart type="donut" options={ options } series={ series } height={ 250 } width={ 250 }/>
  )
}

function CcChart (props) {
  const { submitted, notsubmitted, setSelected } = props
  const [showTotal, setShowTotal] = useState(true)
  const series = useMemo(() => {
    return [submitted, notsubmitted]
  }, [submitted, notsubmitted])
  const options = useMemo(() => {
    return {
      labels: ['Submitted CC Responden', 'Not Submitted CC Responden'],
      legend: {
        show: false
      },
      colors: ['#00D1FF', '#C8C8C8'],
      chart: {
        events: {
          dataPointSelection: (event, chartContext, config) => config.dataPointIndex === 0 ? 
            setSelected(prev => {
              if (prev === 'Submitted') setShowTotal(true)
              else setShowTotal(false)
              return prev === 'Submitted' ? '' : 'Submitted'
            }) : 
            setSelected(prev => {
              if (prev === 'Not Submitted') setShowTotal(true)
              else setShowTotal(false)
              return prev === 'Not Submitted' ? '' : 'Not Submitted'
            })
        }
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: showTotal,
                showAlways: true
              }
            }
          }
        }
      }
    }
  }, [showTotal])

  return (
    <Chart type="donut" options={ options } series={ series } height={ 250 } width={ 250 }/>
  )
}

function ClientChart (props) {
  const { submitted, notsubmitted, setSelected } = props
  const [showTotal, setShowTotal] = useState(true)
  const series = useMemo(() => {
    return [submitted, notsubmitted]
  }, [submitted, notsubmitted])
  const options = useMemo(() => {
    return {
      labels: ['CC Responden', 'Company Clients'],
      legend: {
        show: false
      },
      colors: ['#00D1FF', '#C8C8C8'],
      chart: {
        events: {
          dataPointSelection: (event, chartContext, config) => config.dataPointIndex === 0 ? 
            setSelected(prev => {
              if (prev === 'Submitted') setShowTotal(true)
              else setShowTotal(false)
              return prev === 'Submitted' ? '' : 'Submitted'
            }) : 
            setSelected(prev => {
              if (prev === 'Not Submitted') setShowTotal(true)
              else setShowTotal(false)
              return prev === 'Not Submitted' ? '' : 'Not Submitted'
            })
        }
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: showTotal,
                showAlways: true
              }
            }
          }
        }
      }
    }
  }, [showTotal])

  return (
    <Chart type="donut" options={ options } series={ series } height={ 250 } width={ 250 }/>
  )
}

export default function Master() {
  const [tablePage, setTablePage] = useState(1)
  const [tkoTablePage, setTkoTablePage] = useState(1)
  const [referrerChart, setReferrerChart] = useState('')
  const [tkoChart, setTkoChart] = useState('')
  const [ccChart, setCcChart] = useState('')
  const [clientChart, setClientChart] = useState('')
  const [tableTab, setTableTab] = useState(0)
  const [companies, setCompanies] = useState({})
  const [companiesAmmount, setCompaniesAmmount] = useState(0)
  const dispatch = useDispatch()
  const { access_token } = useContext(AuthContext)
  const { list: responden, tko_list: tko } = useSelector(({ survey }) => survey)
  const { list: referrer } = useSelector(({ referrer }) => referrer)

  useEffect(() => {
    dispatch(SurveyAction.fetch(access_token))
    return () => {
      dispatch({
        type: 'SET_SURVEY',
        payload: []
      })
    }
  }, [])

  useEffect(() => {
    console.log(tko, 'tko')
  }, [tko])

  useEffect(() => {
    if (responden && responden.length > 0) {
      responden.forEach(el => {
        if (!companies[el.Perusahaan.NamaPerusahaan]) {
          setCompanies(prev => ({ ...prev, [el.Perusahaan.NamaPerusahaan]: 1 }))
        }
      })
    }
    console.log(responden, 'responden')
  }, [responden])

  const StarRenderer = (score) => {
    switch (score) {
      case 'Sangat Sesuai Harapan':
        return <Rating name="read-only" value={5} readOnly /> 
      case 'Sesuai Harapan':
        return <Rating name="read-only" value={4} readOnly /> 
      case 'Cukup Sesuai Harapan':
        return <Rating name="read-only" value={3} readOnly /> 
      case 'Kurang Sesuai Harapan':
        return <Rating name="read-only" value={2} readOnly /> 
      case 'Sangat Tidak Sesuai Harapan':
        return <Rating name="read-only" value={1} readOnly /> 
      default:
        return score
    }
  }

  return (
    <PermataWrapper>
      <div className={ Styles.Container }>
        <div className={ Styles.MasterChartContainer }>
          <div className={ Styles.ChartCard }>
            <ReferrerChart setSelected={ setReferrerChart } referrer={ referrer.length } responden={ responden.length }/>
            <div>
              <span>Referrer ammount in percentage compared to Responden ammount in percentage</span>
            </div>
          </div>
          <div className={ Styles.ChartCard }>
            <TkoChart setSelected={ setTkoChart } submitted={ tko.filter(el => el.Saran !== undefined).length } notsubmitted={ tko.filter(el => el.Saran === undefined).length }/>
            <div>
              <span>TKO's submitted survey in percentage</span>
            </div>
          </div>
          <div className={ Styles.ChartCard }>
            <CcChart setSelected={ setCcChart } submitted={ responden.filter(el => el.Saran !== undefined).length } notsubmitted={ responden.filter(el => el.Saran === undefined).length }/>
            <div>
              <span>CC Responden's submitted survey in percentage</span>
            </div>
          </div>
          <div className={ Styles.ChartCard }>
            <ClientChart setSelected={ setClientChart } submitted={ responden.length } notsubmitted={ Object.keys(companies).length }/>
            <div>
              <span>CC Responden ammount compared to Client ammount in percentage</span>
            </div>
          </div>
        </div>
        <div className={ Styles.ContentCard }>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={ tableTab } onChange={ (e, newValue) => setTableTab(newValue) } aria-label="basic tabs example">
              <Tab label="Klien Perusahaan" {...a11yProps(0)} />
              <Tab label="TKO" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={ tableTab } index={ 0 }>
            <div className={ Styles.ContentTable }>
              <TableContainer className={ Styles.Table } component={Paper}>
                
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell align="right">Referrer</TableCell>
                      <TableCell align="right">Unit Direktorat Referrer</TableCell>
                      <TableCell align="right">Unit Manager Referrer</TableCell>
                      <TableCell align="right">Nama Responden</TableCell>
                      <TableCell align="right">Jabatan Responden</TableCell>
                      <TableCell align="right">Unit Kerja Responden</TableCell>
                      <TableCell align="right">Ekstensi Responden</TableCell>
                      <TableCell align="right">Email Responden</TableCell>
                      <TableCell align="right">Telpon HP Responden</TableCell>
                      <TableCell align="right">Nama Perusahaan</TableCell>
                      <TableCell align="right">Alamat Kantor</TableCell>
                      <TableCell align="right">Telpon Kantor</TableCell>
                      <TableCell align="right">Hubungan ke PIS</TableCell>
                      <TableCell align="right">Posisi PIS</TableCell>
                      <TableCell align="right">Lingkup Jasa</TableCell>
                      <TableCell align="right">Type</TableCell>
                      <TableCell align="right">Kualitas Layanan</TableCell>
                      <TableCell align="right">Kualitas TKO</TableCell>
                      <TableCell align="right">Ketepatan Waktu</TableCell>
                      <TableCell align="right">Membangun/memelihara Hubungan Klien</TableCell>
                      <TableCell align="right">Membangun/memelihara Hubungan TKO</TableCell>
                      <TableCell align="right">Respon Penanganan Keluhan</TableCell>
                      <TableCell align="right">Kemudahan Kontak Staf PIS</TableCell>
                      <TableCell align="right">Banding PIS</TableCell>
                      <TableCell align="right">Banding Perusahaan Lain</TableCell>
                      <TableCell align="right">Saran</TableCell>
                      <TableCell align="right">Tanggal Input</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { responden.sort((a, b) => { 
                      if (a.Responden.Name > b.Responden.Name) return 1
                      if (b.Responden.Name > a.Responden.Name) return -1
                      return 0
                    }).slice(0+(5*(tablePage-1)), 5+(5*(tablePage-1))).map((el, ind) => (
                    <TableRow style={ { height: 150 } } key={ ind }>
                      <TableCell>{ ind+1+(5*(tablePage-1)) }</TableCell>
                      <TableCell align="right">{ el.Referrer?.Name.toUpperCase() }</TableCell>
                      <TableCell align="right">{ el.Referrer.UkDir.toUpperCase() }</TableCell>
                      <TableCell align="right">{ el.Referrer.UkMgr.toUpperCase() }</TableCell>
                      <TableCell align="right">{ el.Responden.Nama.toUpperCase() }</TableCell>
                      <TableCell align="right">{ el.Responden.Jabatan.toUpperCase() }</TableCell>
                      <TableCell align="right">{ el.Responden.UnitKerja ? el.Responden.UnitKerja.toUpperCase() : null }</TableCell>
                      <TableCell align="right">{ el.Responden.Ekstensi ? el.Responden.Ekstensi : null }</TableCell>
                      <TableCell align="right">{ el.Responden.Email.toLowerCase() }</TableCell>
                      <TableCell align="right">{ el.Responden.TelponHp }</TableCell>
                      <TableCell align="right">{ el.Perusahaan.NamaPerusahaan.toUpperCase() }</TableCell>
                      <TableCell align="right">{ el.Perusahaan.Alamat ? el.Perusahaan.Alamat.toUpperCase() : null }</TableCell>
                      <TableCell align="right">{ el.Perusahaan.TelponPerusahaan ? el.Perusahaan.TelponPerusahaan : null }</TableCell>
                      <TableCell align="right">{ el.AreaOfInvolvement ? el.AreaOfInvolvement : null }</TableCell>
                      <TableCell align="right">{ el.PosisiPisDiPerusahaan ? el.PosisiPisDiPerusahaan.toUpperCase() : null }</TableCell>
                      <TableCell align="right">{ el.RuangLingkupJasa ? el.RuangLingkupJasa.toUpperCase() : null }</TableCell>
                      <TableCell align="right">{ el.type === 'CC' ? 'KLIEN PERUSAHAAN' : 'TKO' }</TableCell>
                      <TableCell align="right">{ el.KualitasLayanan ? StarRenderer(el.KualitasLayanan.KualitasLayanan) : null }</TableCell>
                      <TableCell align="right">{ el.KualitasLayanan ? StarRenderer(el.KualitasLayanan.KualitasTKO) : null }</TableCell>
                      <TableCell align="right">{ el.KualitasLayanan ? StarRenderer(el.KualitasLayanan.KetepatanWaktu) : null }</TableCell>
                      <TableCell align="right">{ el.KualitasLayanan ? StarRenderer(el.KualitasLayanan.PemeliharaanHubKlien) : null }</TableCell>
                      <TableCell align="right">{ el.KualitasLayanan ? StarRenderer(el.KualitasLayanan.PemeliharaanHubTKO) : null }</TableCell>
                      <TableCell align="right">{ el.Kerjasama ? StarRenderer(el.Kerjasama.ResponPenangananKeluhan) : null }</TableCell>
                      <TableCell align="right">{ el.Kerjasama ? StarRenderer(el.Kerjasama.KemudahanKontakStafPIS) : null }</TableCell>
                      <TableCell align="right">{ el.Banding ? StarRenderer(el.Banding.PIS) : null }</TableCell>
                      <TableCell align="right">{ el.Banding ? (el.Banding.PerusahaanLain.includes(': ') ? StarRenderer(el.Banding.PerusahaanLain.split(': ')[1]) : StarRenderer(el.Banding.PerusahaanLain)) : null }</TableCell>
                      <TableCell align="right">{ el.Saran ? el.Saran.toUpperCase() : null }</TableCell>
                      <TableCell align="right">{ el.InputDate ? el.InputDate.viewable : null }</TableCell>
                    </TableRow>
                    )) }
                  </TableBody>
                </Table>
              </TableContainer>
              <div className={ Styles.TablePagination }>
                <div className={ Styles.ExportWrapper }>
                  <ExportCSV data={ responden.map((el, ind) => ({
                    "No. ": ind + 1,
                    "Referrer": el.Referrer?.Name.toUpperCase(),
                    "Unit Direktorat Referrer": el.Referrer?.UkDir?.toUpperCase(),
                    "Unit Manager Referrer": el.Referrer?.UkMgr?.toUpperCase(),
                    "Nama Responden": el.Responden.Nama.toUpperCase(),
                    "Jabatan Responden": el.Responden.Jabatan.toUpperCase(),
                    "Unit Kerja Responden": el.Responden.UnitKerja ? el.Responden.UnitKerja.toUpperCase() : null,
                    "No Ekstensi Responden": el.Responden.Ekstensi ? el.Responden.Ekstensi.toUpperCase() : null,
                    "Email Responden": el.Responden.Email.toLowerCase(),
                    "Telpon Hp Responden": el.Responden.TelponHp,
                    "Nama Perusahaan": el.Perusahaan.NamaPerusahaan.toUpperCase(),
                    "Alamat Kantor": el.Perusahaan.Alamat ? el.Perusahaan.Alamat.toUpperCase() : null,
                    "Telpon Kantor": el.Perusahaan.TelponPerusahaan ? el.Perusahaan.TelponPerusahaan : null,
                    "Hubungan ke PIS": el.AreaOfInvolvement ? el.AreaOfInvolvement.toUpperCase() : null,
                    "Posisi PIS di Perusahaan": el.PosisiPisDiPerusahaan ? el.PosisiPisDiPerusahaan.toUpperCase() : null,
                    "Ruang Lingkup Jasa": el.RuangLingkupJasa ? el.RuangLingkupJasa.toUpperCase() : null,
                    "Type": el.type === 'CC' ? 'KLIEN PERUSAHAAN' : 'TKO',
                    "Kualitas Layanan": el.KualitasLayanan ? el.KualitasLayanan.KualitasLayanan.toUpperCase() : null,
                    "Kualitas TKO": el.KualitasLayanan ? el.KualitasLayanan.KualitasTKO.toUpperCase() : null,
                    "Ketepatan Waktu": el.KualitasLayanan ? el.KualitasLayanan.KetepatanWaktu.toUpperCase() : null,
                    "Membangun/memelihara Hubungan Klien": el.KualitasLayanan ? el.KualitasLayanan.PemeliharaanHubKlien.toUpperCase() : null,
                    "Membangun/memelihara Hubungan TKO": el.KualitasLayanan ? el.KualitasLayanan.PemeliharaanHubTKO.toUpperCase() : null,
                    "Aplikasi Layanan Inti(MWS)": el.TeknologiInformasi && el.TeknologiInformasi.AplikasiMWS ? el.TeknologiInformasi.AplikasiMWS.toUpperCase() : null,
                    "Aplikasi pendukung pekerjaan": el.TeknologiInformasi && el.TeknologiInformasi.AplikasiPendukungPekerjaan ? el.TeknologiInformasi.AplikasiPendukungPekerjaan.toUpperCase() : null,
                    "Aplikasi employee benefit/loan": el.TeknologiInformasi && el.TeknologiInformasi.AplikasiEmployeeBenefit ? el.TeknologiInformasi.AplikasiEmployeeBenefit.toUpperCase() : null,
                    "Respon Penanganan Keluhan": el.Kerjasama ? el.Kerjasama.ResponPenangananKeluhan.toUpperCase() : null,
                    "Kemudahan Kontak Staf PIS": el.Kerjasama ? el.Kerjasama.KemudahanKontakStafPIS.toUpperCase() : null,
                    "Banding PIS": el.Banding ? el.Banding.PIS.toUpperCase() : null,
                    "Banding Perusahaan Lain": el.Banding ? el.Banding.PerusahaanLain.toUpperCase() : null,
                    "Saran": el.Saran ? el.Saran.toUpperCase() : null,
                    "Tanggal Input": el.InputDate ? el.InputDate.viewable : null
                  })) } filename="hasil_css_survey">Export</ExportCSV>
                </div>
                <Pagination page={ tablePage } onChange={ (e, val) => setTablePage(val) } count={ Math.ceil(responden.length/5) }/>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={ tableTab } index={ 1 }>
            <div className={ Styles.ContentTable }>
              <TableContainer className={ Styles.Table } component={Paper}>
                
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell align="right">Nama Responden</TableCell>
                      <TableCell align="right">Jabatan Responden</TableCell>
                      <TableCell align="right">Telpon HP Responden</TableCell>
                      <TableCell align="right">Nama Perusahaan</TableCell>
                      <TableCell align="right">Kota</TableCell>
                      <TableCell align="right">Kerja Sejak</TableCell>
                      <TableCell align="right">Regional</TableCell>
                      <TableCell align="right">Status TKO</TableCell>
                      <TableCell align="right">Type</TableCell>
                      <TableCell align="right">Rekrutmen</TableCell>
                      <TableCell align="right">Penempatan Kerja</TableCell>
                      <TableCell align="right">Penggunaan BPJS</TableCell>
                      <TableCell align="right">Payroll Gaji</TableCell>
                      <TableCell align="right">Perpanjangan Kontrak</TableCell>
                      <TableCell align="right">Respon Penanganan Keluhan</TableCell>
                      <TableCell align="right">Kemudahan Kontak Staf PIS</TableCell>
                      <TableCell align="right">Banding PIS</TableCell>
                      <TableCell align="right">Banding Perusahaan Lain</TableCell>
                      <TableCell align="right">Adakah Pungutan Biaya</TableCell>
                      <TableCell align="right">Saran</TableCell>
                      <TableCell align="right">Tanggal Input</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { tko.sort((a, b) => { 
                      if (a.Responden.Name > b.Responden.Name) return 1
                      if (b.Responden.Name > a.Responden.Name) return -1
                      return 0
                    }).slice(0+(5*(tkoTablePage-1)), 5+(5*(tkoTablePage-1))).map((el, ind) => (
                    <TableRow style={ { height: 150 } } key={ ind }>
                      <TableCell>{ ind+1+(5*(tkoTablePage-1)) }</TableCell>
                      <TableCell align="right">{ el.Responden.Nama.toUpperCase() }</TableCell>
                      <TableCell align="right">{ el.Responden.Jabatan ? el.Responden.Jabatan.toUpperCase() : null }</TableCell>
                      <TableCell align="right">{ el.Responden.TelponHp }</TableCell>
                      <TableCell align="right">{ el.Perusahaan.NamaPerusahaan.toUpperCase() }</TableCell>
                      <TableCell align="right">{ el.Perusahaan.Kota.toUpperCase() }</TableCell>
                      <TableCell align="right">{ el.Perusahaan.KerjaSejak.toUpperCase() }</TableCell>
                      <TableCell align="right">{ el.Origin.regional.toUpperCase() }</TableCell>
                      <TableCell align="right">{ el.StatusTKO.toUpperCase() }</TableCell>
                      <TableCell align="right">{ el.type === 'CC' ? 'KLIEN PERUSAHAAN' : 'TKO' }</TableCell>
                      <TableCell align="right">{ el.KualitasLayanan ? StarRenderer(el.KualitasLayanan.Rekrutmen) : null }</TableCell>
                      <TableCell align="right">{ el.KualitasLayanan ? StarRenderer(el.KualitasLayanan.PenempatanKerja) : null }</TableCell>
                      <TableCell align="right">{ el.KualitasLayanan ? StarRenderer(el.KualitasLayanan.PenggunaanBpjs) : null }</TableCell>
                      <TableCell align="right">{ el.KualitasLayanan ? StarRenderer(el.KualitasLayanan.PayrollGaji) : null }</TableCell>
                      <TableCell align="right">{ el.KualitasLayanan ? StarRenderer(el.KualitasLayanan.PerpanjanganKontrak) : null }</TableCell>
                      <TableCell align="right">{ el.Kerjasama ? StarRenderer(el.Kerjasama.ResponPenangananKeluhan) : null }</TableCell>
                      <TableCell align="right">{ el.Kerjasama ? StarRenderer(el.Kerjasama.KemudahanKontakStafPIS) : null }</TableCell>
                      <TableCell align="right">{ el.Banding ? StarRenderer(el.Banding.PIS) : null }</TableCell>
                      <TableCell align="right">{ el.Banding ? (el.Banding.PerusahaanLain.includes(': ') ? StarRenderer(el.Banding.PerusahaanLain.split(': ')[1]) : StarRenderer(el.Banding.PerusahaanLain)) : null }</TableCell>
                      <TableCell align="right">{ el.AdakahPungutanBiaya ? el.AdakahPungutanBiaya.toUpperCase() : null }</TableCell>
                      <TableCell align="right">{ el.Saran ? el.Saran.toUpperCase() : null }</TableCell>
                      <TableCell align="right">{ el.InputDate ? el.InputDate.viewable : null }</TableCell>
                    </TableRow>
                    )) }
                  </TableBody>
                </Table>
              </TableContainer>
              <div className={ Styles.TablePagination }>
                <div className={ Styles.ExportWrapper }>
                  <ExportCSV data={ tko.map((el, ind) => ({
                    "No. ": ind + 1,
                    "Nama Responden": el.Responden.Nama.toUpperCase(),
                    "NRK Responden": el.Origin?.nrk,
                    "Tanggal Resign Responden": el.Origin?.resign_date,
                    "Jabatan Responden": el.Responden.Jabatan ? el.Responden.Jabatan.toUpperCase() : null,
                    "Telpon Hp Responden": el.Responden.TelponHp,
                    "Nama Perusahaan": el.Perusahaan.NamaPerusahaan.toUpperCase(),
                    "Kota": el.Perusahaan.Kota ? el.Perusahaan.Kota.toUpperCase() : null,
                    "Kerja Sejak": el.Perusahaan?.KerjaSejak ? el.Perusahaan?.KerjaSejak : null,
                    "Regional": el.Origin.regional.toUpperCase(),
                    "Status TKO": el.StatusTKO.toUpperCase(),
                    "Type": el.type === 'CC' ? 'KLIEN PERUSAHAAN' : 'TKO',
                    "Rekrutmen": el.KualitasLayanan ? (el.KualitasLayanan.Rekrutmen ? el.KualitasLayanan.Rekrutmen.toUpperCase() : 'SANGAT SESUAI HARAPAN') : null,
                    "Penempatan Kerja": el.KualitasLayanan ? (el.KualitasLayanan.PenempatanKerja ? el.KualitasLayanan.PenempatanKerja.toUpperCase() : 'SANGAT SESUAI HARAPAN' ) : null,
                    "Penggunaan BPJS": el.KualitasLayanan ? (el.KualitasLayanan.PenggunaanBpjs ? el.KualitasLayanan.PenggunaanBpjs.toUpperCase() : 'SANGAT SESUAI HARAPAN') : null,
                    "Payroll Gaji": el.KualitasLayanan ? (el.KualitasLayanan.PayrollGaji ? el.KualitasLayanan.PayrollGaji.toUpperCase() : 'SANGAT SESUAI HARAPAN' ) : null,
                    "Perpanjangan Kontrak": el.KualitasLayanan ? (el.KualitasLayanan.PerpanjanganKontrak ? el.KualitasLayanan.PerpanjanganKontrak.toUpperCase() : 'SANGAT SESUAI HARAPAN') : null,
                    "Aplikasi Layanan Inti(MWS)": el.TeknologiInformasi && el.TeknologiInformasi.AplikasiMWS ? el.TeknologiInformasi.AplikasiMWS.toUpperCase() : null,
                    "Aplikasi pendukung pekerjaan": el.TeknologiInformasi && el.TeknologiInformasi.AplikasiPendukungPekerjaan ? el.TeknologiInformasi.AplikasiPendukungPekerjaan.toUpperCase() : null,
                    "Aplikasi employee benefit/loan": el.TeknologiInformasi && el.TeknologiInformasi.AplikasiEmployeeBenefit ? el.TeknologiInformasi.AplikasiEmployeeBenefit.toUpperCase() : null,
                    "Respon Penanganan Keluhan": el.Kerjasama ? (el.Kerjasama.ResponPenangananKeluhan ? el.Kerjasama.ResponPenangananKeluhan.toUpperCase() : 'SANGAT SESUAI HARAPAN') : null,
                    "Kemudahan Kontak Staf PIS": el.Kerjasama ? (el.Kerjasama.KemudahanKontakStafPIS ? el.Kerjasama.KemudahanKontakStafPIS.toUpperCase() : 'SANGAT SESUAI HARAPAN') : null,
                    "Banding PIS": el.Banding ? (el.Banding.PIS ? el.Banding.PIS.toUpperCase() : 'SANGAT SESUAI HARAPAN') : null,
                    "Banding Perusahaan Lain": el.Banding ? (el.Banding.PerusahaanLain ? el.Banding.PerusahaanLain.toUpperCase() : '') : null,
                    "Adakah Pungutan Biaya": el.AdakahPungutanBiaya ? el.AdakahPungutanBiaya.toUpperCase() : null,
                    "Saran": el.Saran ? el.Saran.toUpperCase() : null,
                    "Tanggal Input": el.InputDate ? el.InputDate.viewable : null
                  })) } filename="hasil_tko_survey">Export</ExportCSV>
                </div>
                <Pagination page={ tkoTablePage } onChange={ (e, val) => setTkoTablePage(val) } count={ Math.ceil(tko.length/5) }/>
              </div>
            </div>
          </TabPanel>
          
        </div>
      </div>
    </PermataWrapper>
  )
}
