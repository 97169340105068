import { createContext, useState, useEffect } from 'react'

const AuthContext = createContext({
  _id: '',
  login: (access_token, _id) => {},
  logout: () => {},
  setRemember: (val) => {},
  access_token: '',
  remember: 0
})

export const AuthContextProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    _id: '',
    access_token: '',
    remember: 0
  })

  useEffect(() => {
    let access_token = localStorage.getItem('access_token')
    let _id = localStorage.getItem('_id')

    if (access_token && _id) setAuth((prev) => ({ ...prev, access_token, _id }))
    else {
      access_token = sessionStorage.getItem('access_token')
      _id = sessionStorage.getItem('_id')
      if (access_token && _id) setAuth((prev) => ({ ...prev, access_token, _id }))
    }
  }, [])

  const login = (access_token, _id) => {
    setAuth((prev) => ({ ...prev, access_token, _id }))
    if (auth.remember) {
      localStorage.setItem('access_token', access_token)
      localStorage.setItem('_id', _id)
    }
    sessionStorage.setItem('access_token', access_token)
    sessionStorage.setItem('_id', _id)
  }

  const logout = () => {
    setAuth({ remember: 0, access_token: '', _id: '' })
    localStorage.clear()
    sessionStorage.clear()
  }

  const setRemember = (val) => {
    setAuth((prev) => ({ ...prev, remember: val }))
  }

  const context = { ...auth, login, logout, setRemember }

  return (
    <AuthContext.Provider value={ context }>
      { children }
    </AuthContext.Provider>
  )
}

export default AuthContext