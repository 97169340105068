const referrerState = {
  list: []
}

const referrerReducer = (state = referrerState, action) => {
  switch (action.type) {
    case 'SET_REFERRER':
      return { ...state, list: action.payload }
    default:
      return state
  }
}

export default referrerReducer