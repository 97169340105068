const surveyState = {
  list: [],
  tko_list: []
}

const surveyReducer = (state = surveyState, action) => {
  switch (action.type) {
    case 'SET_SURVEY':
      return { ...state, list: action.payload }
    case 'SET_TKO_SURVEY':
      return { ...state, tko_list: action.payload }
    default:
      return state
  }
}

export default surveyReducer