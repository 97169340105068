import Styles from './styles.module.scss'
import React from 'react'
import PERMATA_LIGHT_LOGO from '../../assets/image/permata_logo_light.png'

export default function PermataQuizWrapper(props) {
  const { children, title } = props
  return (
    <div className={ Styles.Container }>
      <div className={ Styles.LogoWrapper }>
        <img alt="permata-light-logo" src={ PERMATA_LIGHT_LOGO } />
        <div className={ Styles.QuizTitleWrapper }>
          { title.split(' ').length === 2 ? (
            <div className={ Styles.Divided }>
              <div>
                <span>{ title.split(' ')[0] }</span>
              </div>
              <div>
                <span>{ title.split(' ')[1] }</span>
              </div>
            </div>
          ) : (
            <div className={ Styles.United }>
              <span>{ title }</span>
            </div>
          ) }
        </div>
      </div>
      <div className={ Styles.Content }>
        { children }
      </div>
    </div>
  )
}
