import Styles from './styles.module.scss'
import React, { useState, useContext } from 'react'
import { ButtonLoader } from '../../components'
import AuthContext from '../../store/AuthContext'
import asyncLogin from '../../async/login'
import { TextField } from '@mui/material'
import PERMATA_ERESPONDEN_LOGO from '../../assets/image/permata_eresponden_logo.png'

export default function LoginPage() {
  const [loading, setLoading] = useState(0)
  const [user, setUser] = useState({
    Nrk: '',
    Password: ''
  })
  const [errorMessage, setErrorMessage] = useState('')
  const { login } = useContext(AuthContext)

  const handleLogin = async (e) => {
    try {
      e.preventDefault()
      setLoading(1)
      const res = await asyncLogin(user)
      login(res.access_token, res._id)
      if (res._id === '6167cbc7bd3541bafd74d773') sessionStorage.setItem('Master', 1)
      setLoading(0)
    } catch(err) {
      console.log(err)
      if (err.response) {
        if (err.response.data.message === 'Wrong password') setErrorMessage('Incorrect password')
        if (err.response.data.message === 'Nrk unregistered') setErrorMessage('Nrk unregistered')
      }
      setLoading(0)
    }
  }

  const handleChangeUser = (e) => {
    setUser(prev => ({ ...prev, [e.target.name]: e.target.value }))
    if (e.target.name === 'Nrk') if (errorMessage === 'Nrk unregistered') setErrorMessage('')
    else if (e.target.name === 'Password') if (errorMessage === 'Incorrect password') setErrorMessage('')
  }

  return (
    <div className={ Styles.Container }>
      <div className={ Styles.LogoWrapper }>
        <img alt="permata-eresponden-logo" src={ PERMATA_ERESPONDEN_LOGO } />
      </div>
      <div className={ Styles.FormWrapper }>
        <div className={ Styles.LittleLogoWrapper }>
          <img alt="permata-eresponen-logo" src={ PERMATA_ERESPONDEN_LOGO } />
        </div>
        <form className={ Styles.FormCard } onSubmit={ handleLogin }>
          <div className={ `${ Styles.FormControl } ${ Styles.FirstItem }` }>
            <TextField value={ user.Nrk } onChange={ handleChangeUser } error={ errorMessage === 'Nrk unregistered' && true } helperText={ errorMessage === 'Nrk unregistered' && errorMessage } name="Nrk" label="NRK" variant="outlined" fullWidth required/>
          </div>
          <div className={ Styles.FormControl }>
            <TextField value={ user.Password } onChange={ handleChangeUser } error={ errorMessage === 'Incorrect password' && true } helperText={ errorMessage === 'Incorrect password' && errorMessage } name="Password" label="Password" type="password" variant="outlined" fullWidth required/>
          </div>
          <div className={ Styles.SubmitControl }>
            <ButtonLoader loading={ loading } type="submit">
              <span>SIGN IN</span>
            </ButtonLoader>
          </div>
        </form>
      </div>
    </div>
  )
}
