const respondenState = {
  list: []
}

const respondenReducer = (state = respondenState, action) => {
  switch (action.type) {
    case 'SET_RESPONDEN_LIST':
      return { ...state, list: action.payload }
    default:
      return state
  }
}

export default respondenReducer