import React from 'react'
import Lottie from 'react-lottie'
import THANK_YOU_ANIMATION from '../../assets/lottie/thank_you_anim.json'
import { PermataIdleBackground } from '../../components'

export default function index() {
  const animOption = {
    loop: false,
    autoplay: true, 
    animationData: THANK_YOU_ANIMATION
  }

  return (
    <PermataIdleBackground>
      <Lottie options={ animOption } height={ 1000 } width={ 1200 }/>
    </PermataIdleBackground>
  )
}