import axios from '../axios'

const login = (loginPayload) => new Promise((resolve, reject) => {
  axios({
    method: 'POST',
    url: '/login',
    data: loginPayload
  }).then(({ data }) => {
    resolve(data)
  }).catch(err => {
    reject(err)
  })
})

export default login