import Styles from './styles.module.scss'
import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import PERMATA_DARK_LOGO from '../../assets/image/permata_logo_dark.png'
import { Drawer, Box, List, ListItem, ListItemIcon, ListItemText, IconButton, Dialog,
DialogTitle, DialogActions, Button } from '@mui/material'
import { Logout as LogoutIcon, MoreVert as DrawerIcon, Storage as ReferrerIcon, Dashboard as DashboardIcon } from '@mui/icons-material'
import AuthContext from '../../store/AuthContext'
import { useHistory, useRouteMatch } from 'react-router-dom'

export default function PermataWrapper(props) {
  const { children } = props
  const [rightDrawer, setRightDrawer] = useState(false)
  const [logoutDialog, setLogoutDialog] = useState(false)
  const { logout } = useContext(AuthContext)
  const history = useHistory()
  const { path } = useRouteMatch()
  const { name, nrk } = useSelector(({ profile }) => profile)

  const handleLogout = () => {
    logout()
    history.push('/')
  }

  return (
    <div className={ Styles.Container }>
      <Dialog open={ logoutDialog } onClose={ () => setLogoutDialog(false) }>
        <DialogTitle>
          Are you sure you want to sign out ?
        </DialogTitle>
        <DialogActions>
          <Button color="error" onClick={ handleLogout } variant="outlined">SIGN OUT</Button>
        </DialogActions>
      </Dialog>
      <Drawer anchor="right" open={ rightDrawer } onClose={ () => setRightDrawer(false) }>
        <Box sx={ { width: 200 } }>
          <div className={ Styles.IdentityWrapper }>
            <div className={ Styles.NameWrapper }>
              <span>{ name }</span>
            </div>
            <div className={ Styles.NrkWrapper }>
              <span>{ nrk }</span>
            </div>
          </div>
          <List>
            { path !== '/' && (
            <ListItem onClick={ () => history.push('/') } className={ Styles.DrawerButton } button>
              <ListItemIcon>
                <DashboardIcon className={ Styles.DrawerButtonIcon }/>
              </ListItemIcon>
              <ListItemText>
                DASHBOARD
              </ListItemText>
            </ListItem>
            ) }
            { path !== '/referrer' && (
            <ListItem onClick={ () => history.push('/referrer') } className={ Styles.DrawerButton } button>
              <ListItemIcon>
                <ReferrerIcon className={ Styles.DrawerButtonIcon }/>
              </ListItemIcon>
              <ListItemText>
                REFERRER
              </ListItemText>
            </ListItem>
            ) }
            <ListItem onClick={ () => setLogoutDialog(true) } className={ `${ Styles.DrawerButton } ${ Styles.Logout }` } button>
              <ListItemIcon>
                <LogoutIcon className={ Styles.DrawerButtonIcon }/>
              </ListItemIcon>
              <ListItemText>
                SIGN OUT
              </ListItemText>
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <div className={ Styles.LogoWrapper }>
        <img alt="permata-dark-logo" src={ PERMATA_DARK_LOGO } />
        <div className={ Styles.MoreButtonWrapper }>
          <IconButton onClick={ () => setRightDrawer(true) } className={ Styles.MoreButton }>
            <DrawerIcon className={ Styles.MoreButtonIcon }/>
          </IconButton>
        </div>
      </div>
      <div className={ Styles.Content }>
        { children }
      </div>
    </div>
  )
}
