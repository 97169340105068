import React from 'react'
import Styles from './styles.module.scss'
import PERMATA_LOGO from '../../assets/image/permata_logo.png'
import { useHistory } from 'react-router'

export default function PermataIdleBackground(props) {
  const { children } = props
  const history = useHistory()
  return (
    <div className={ Styles.Container }>
      <div className={ Styles.AbsPermataLogo }>
        <img alt="permata-logo" onClick={ () => history.push('/') } src={ PERMATA_LOGO }/>
      </div>
      { children }
    </div>
  )
}
