import React from 'react'
import Lottie from 'react-lottie'
import NOT_FOUND_ANIMATION from '../../assets/lottie/not_found_anim.json'
import { PermataIdleBackground } from '../../components'

export default function NotFound() {
  const animOption = {
    loop: true,
    autoplay: true, 
    animationData: NOT_FOUND_ANIMATION
  }

  return (
    <PermataIdleBackground>
      <Lottie options={ animOption }/>
    </PermataIdleBackground>
  )
}
