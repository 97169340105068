import './App.scss';
import React, { useContext, useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux'
import { LoginPage, Dashboard, Quizionaire, NotFoundPage, LoadingPage, ReferrerPage } from './pages';
import store from './store/redux'
import AuthContext from './store/AuthContext';
import RespondenAction from './store/redux/responden/action'
import ReferrerAction from './store/redux/referrer/action'
import ProfileAction from './store/redux/profile/action';

function App() {
  const [loading, setLoading] = useState(1)
  const { access_token } = useContext(AuthContext)

  useEffect(() => {
    setLoading(0)
  }, [])
  
  if (loading) {
    return (
      <LoadingPage/>
    )
  }
  if (access_token) {
    return (
      <Provider store={ store }>
        <AuthPagination/>
      </Provider>
    )
  } else {
    return (
      <Provider store={ store }>
        <Router>
          <Switch>
            <Route path="/tko/:id">
              <Quizionaire type="TKO"/>
            </Route>
            <Route path="/tko">
              <Quizionaire type="TKO"/>
            </Route>
            <Route path="/cc/:id">
              <Quizionaire type="CC"/>
            </Route>
            <Route path="/cc">
              <Quizionaire type="CC"/>
            </Route>
            <Route path="/:id">
              <NotFoundPage/>
            </Route>
            <Route path="/">
              <LoginPage/>
            </Route>
          </Switch>
        </Router>
      </Provider>
    )
  }
}

const AuthPagination = () => {
  const dispatch = useDispatch()
  const { access_token } = useContext(AuthContext)

  useEffect(() => {
    dispatch(ProfileAction.introduct(access_token))
    dispatch(ReferrerAction.fetch(access_token))
    dispatch(RespondenAction.fetch(access_token))
  }, [access_token])

  return (
    <Router>
      <Switch>
        <Route path="/tko">
          <Quizionaire type="TKO"/>
        </Route>
        <Route path="/cc">
          <Quizionaire type="CC"/>
        </Route>
        <Route path="/referrer">
          <ReferrerPage/>
        </Route>
        <Route path="/:id">
          <NotFoundPage/>
        </Route>
        <Route path="/">
          <Dashboard/>
        </Route>
      </Switch>
    </Router>
  )
}

export default App;
