import React from 'react'
import { TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000000"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline::after": {
      color: "#000000"
    }
  },
  cssLabel: {
    root: {
      color: 'red'
    },
    "&.Mui-focused": {
      color: "#000000"
    }
  }
})

export default function MuiTextField(props) {
  const classes = useStyles()

  return (
    <TextField className={ classes.root } InputLabelProps={ { classes: { root: classes.cssLabel } } } { ...props }/>
  )
}
