import axios from '../../../axios'

export default class RespondenAction {
  static fetch = (access_token) => async (dispatch, getState) => {
    try {
      const { data } = await axios({
        method: 'GET',
        url: '/responden',
        headers: {
          access_token
        }
      })
      
      const { data: respondens, code } = data
      if (code === 200) {
        dispatch({
          type: 'SET_RESPONDEN_LIST',
          payload: respondens
        })
      }
    } catch(err) {
      console.log(err)
    }
  } 
}