import axios from '../axios'

const getSurvey = (phone, isTko) => new Promise((resolve, reject) => {
  axios({
    url: isTko ? '/survey/' + phone + '?isTko=1' : '/survey/' + phone,
    method: 'GET'
  }).then(({ data }) => {
    resolve(data)
  }).catch(err => {
    reject(err)
  })
})

export default getSurvey