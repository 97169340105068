import Styles from './styles.module.scss'
import React, { useMemo } from 'react'
import { CircularProgress } from '@mui/material'

export default function ButtonLoader(props) {
  const { children, loading, fullwidth, halfwidth, className } = props
  const width = useMemo(() => {
    if (fullwidth) return '100%'
    else if (halfwidth) return '50%'
    else return null
  }, [fullwidth, halfwidth])
  return (
    <button { ...props } style={ width && { width } } className={ `${ Styles.ButtonWrapper } ${ className }` }>
      { loading ? (
        <CircularProgress color="inherit" />
      ) : (
        children
      ) }
    </button>
  )
}
