import React from 'react'
import Lottie from 'react-lottie'
import LOADING_ANIMATION from '../../assets/lottie/circular_loading_anim.json'
import { PermataIdleBackground } from '../../components'

export default function index() {
  const animOption = {
    loop: true,
    autoplay: true, 
    animationData: LOADING_ANIMATION
  }

  return (
    <PermataIdleBackground>
      <Lottie options={ animOption }/>
    </PermataIdleBackground>
  )
}
