const profileState = {
  name: '',
  nrk: ''
}

const profileReducer = (state = profileState, action) => {
  switch (action.type) {
    case 'SET_PROFILE':
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export default profileReducer