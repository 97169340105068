import Styles from './styles.module.scss'
import React, { useState, useEffect } from 'react'
import { PermataQuizWrapper, Quiz, LabeledCustomCheckbox, ButtonLoader } from '../../components'
import { InputAdornment, TextField, Modal, Alert, AlertTitle, MenuItem } from '@mui/material'
import { Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import getSurvey from '../../async/getSurvey'
import { submitCcSurvey, submitTkoSurvey } from '../../async/submitSurvey'
import { SubmittedPage, LoadingPage } from '../'
import { useParams } from 'react-router-dom'
import GetSurveyById from '../../async/getSurveyById'

export default function Quizionaire(props) {
  const { type } = props
  const [findPhoneLoading, setFindPhoneLoading] = useState(false)
  const [findPhoneError, setFindPhoneError] = useState('')
  const [loading, setLoading] = useState(true)
  const [phone, setPhone] = useState('')
  const [phoneModal, setPhoneModal] = useState(true)
  const [pageIsFinished, setPageIsFinished] = useState(false) 
  const [alasanYaPungutanBiaya, setAlasanYaPungutanBiaya] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [involvementSelect, setInvolvementSelect] = useState(true)
  const [tkoData, setTkoData] = useState({
    _id: '',
    Nama: '',
    Jabatan: '',
    NamaPerusahaan: '',
    TelponHp: '',
    Kota: '',
    StatusTKO: '',
    Rekrutmen: '',
    PenempatanKerja: '',
    PenggunaanBpjs: '',
    PayrollGaji: '',
    PerpanjanganKontrak: '',
    ResponPenangananKeluhan: '',
    KemudahanKontakStafPIS: '',
    AdakahPungutanBiaya: '',
    PIS: '',
    PerusahaanLain: '',
    Saran: '',
    KerjaSejak: ''
  })
  const [ccData, setCcData] = useState({
    _id: '',
    Nama: '',
    Jabatan: '',
    NamaPerusahaan: '',
    Email: '',
    TelponHp: '',
    Alamat: '',
    PosisiPisDiPerusahaan: '',
    RuangLingkupJasa: '',
    KualitasLayanan: '',
    KualitasTKO: '',
    KetepatanWaktu: '',
    PemeliharaanHubKlien: '',
    PemeliharaanHubTKO: '',
    ResponPenangananKeluhan: '',
    KemudahanKontakStafPIS: '',
    PIS: '',
    PerusahaanLain: '',
    Saran: '',
    TelponPerusahaan: '',
    UnitKerja: '',
    Ekstensi: '',
    AreaOfInvolvement: ''
  })
  const params = useParams()

  useEffect(() => {
    console.log(type, params.id, 'tesss')
    if (type === 'TKO' && params.id) 
      GetSurveyById(params.id, 'tko').then(({ data: { data } }) => {
        if (data.err)
          throw 'error'
        setTkoData(prev => ({ ...prev, _id: data._id, Nama: data.Responden.Nama, Jabatan: data.Responden.Jabatan, NamaPerusahaan: data.Perusahaan.NamaPerusahaan, TelponHp: data.Responden.TelponHp.replace('62', ''), Kota: data.Perusahaan.Kota, KerjaSejak: data.Perusahaan.KerjaSejak, StatusTKO: data.StatusTKO }))
        if (data.Banding && data.Banding.PIS) setPageIsFinished(true)
        setPhoneModal(false)
      }).catch(err => console.log(err, 'tes error'))
    else if (type === 'CC' && params.id)
      GetSurveyById(params.id, 'cc').then(({ data: { data } }) => {
        if (data.err)
          throw 'error'
          console.log(data)
        setCcData(prev => ({ ...prev, _id: data._id, Nama: data.Responden.Nama, Jabatan: data.Responden.Jabatan, NamaPerusahaan: data.Perusahaan.NamaPerusahaan, Email: data.Responden.Email, TelponHp: data.Responden.TelponHp.replace('62', '') }))
        if (data.Banding && data.Banding.PIS) setPageIsFinished(true)
        setPhoneModal(false)
      }).catch(err => console.log(err))
  }, [])

  useEffect(() => {
    setLoading(false)
  }, [type])

  const handleSubmitQuiz = async (e) => {
    try {
      e.preventDefault()
      setLoading(true)
      if (type === 'TKO') {
        for (let key in tkoData) {
          if (!tkoData[key]) throw { name: 'Survey Unfinished' }
        }
      } else if (type === 'CC') {
        for (let key in ccData) {
          if (!ccData[key]) throw { name: 'Survey Unfinished' }
        }
      }
      if (type === 'TKO') await submitTkoSurvey(tkoData._id, { ...tkoData, TelponHp: '62' + tkoData.TelponHp, AdakahPungutanBiaya: alasanYaPungutanBiaya && tkoData.AdakahPungutanBiaya === 'Ya' ? `Ya, alasan: ${ alasanYaPungutanBiaya }` : tkoData.AdakahPungutanBiaya })
      else await submitCcSurvey(ccData._id, { ...ccData, TelponHp: '62' + ccData.TelponHp, TelponPerusahaan: '62' + ccData.TelponPerusahaan })
      setPageIsFinished(true)
      setLoading(false)
    } catch (err) {
      // console.log(err)
      if (err.name && err.name === 'Survey Unfinished') {
        setLoading(false)
        setErrorMessage(`You haven't Finished the survey`)
      }
      // console.log(err, 'handleSubmitQuiz')
    }
  }

  const handleChangeCcData = (e) => {
    if (e.target.banding && e.target.name === 'PerusahaanLain') setCcData(prev => ({ ...prev, [e.target.name]: `${ e.target.banding }: ${ e.target.value }` }))
    else {
      if (e.target.name === 'TelponPerusahaan' || e.target.name === 'TelponHp' || e.target.name === 'Ekstensi') setCcData(prev => ({ ...prev, [e.target.name]: e.target.value.replace(/[^0-9]/g, '') }))
      else {
        setCcData(prev => ({ ...prev, [e.target.name]: e.target.value }))
        console.log(ccData)
      }
    }
  }

  const handleChangeTkoData = (e) => {
    if (e.target.name === 'AdakahPungutanBiaya') setTkoData(prev => ({ ...prev, [e.target.name]: e.target.value }))
    else if (e.target.banding && e.target.name === 'PerusahaanLain') setTkoData(prev => ({ ...prev, [e.target.name]: `${ e.target.banding }: ${ e.target.value }` }))
    else setTkoData(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const handleClosePhoneModal = () => {
    if (type === 'CC') {
      if (ccData.TelponHp) setPhoneModal(false)
      else console.log(`Error can't close`)
    } else if (type === 'TKO') {
      if (tkoData.TelponHp) setPhoneModal(false)
      else console.log(`Error can't close`)
    } else {
      console.log(`Error can't close`)
    }
  }

  const handleChangeFindByPhone = (e) => {
    setFindPhoneError('')
    setPhone(e.target.value.replace(/[^0-9]/g, '')) 
  }

  const handleFindByPhone = (e) => {
    e.preventDefault()
    setFindPhoneLoading(true)
    getSurvey(`62${ phone }`, type === 'TKO' ? true : undefined).then(({ data }) => {
      if (type === 'CC' && data.type === 'TKO') throw new Error('NotFound')
      if (type === 'TKO' && data.type === 'CC') throw new Error('NotFound')
      if (type === 'CC') setCcData(prev => ({ ...prev, _id: data._id, Nama: data.Responden.Nama, Jabatan: data.Responden.Jabatan, NamaPerusahaan: data.Perusahaan.NamaPerusahaan, Email: data.Responden.Email, TelponHp: data.Responden.TelponHp.replace('62', '') }))
      else if (type === 'TKO') setTkoData(prev => ({ ...prev, _id: data._id, Nama: data.Responden.Nama, Jabatan: data.Responden.Jabatan, NamaPerusahaan: data.Perusahaan.NamaPerusahaan, TelponHp: data.Responden.TelponHp.replace('62', ''), Kota: data.Perusahaan.Kota, KerjaSejak: data.Perusahaan.KerjaSejak, StatusTKO: data.StatusTKO }))
      if (data.Banding && data.Banding.PIS) setPageIsFinished(true)
      setPhoneModal(false)
    }).catch(err => {
      setFindPhoneError('Nomor telepon belum terdaftar')
    }).finally(() => {
      setFindPhoneLoading(false)
    })
  }

  const handleChangeCcAreaInvolvement = (e) => {
    if (e.target.value !== 'Other') {
      handleChangeCcData(e)
    } else {
      setInvolvementSelect(false)
    }
  }

  if (loading) return (
    <LoadingPage/>
  )

  if (pageIsFinished) return (
    <SubmittedPage/>
  )

  return (
    <PermataQuizWrapper title={ type === 'TKO' ? 'TKO' : 'Company Client' }>
      <div className={ Styles.ErrorWrapper }>
        { errorMessage && (
        <Alert onClose={ () => setErrorMessage('') } severity="error">
          <AlertTitle>Error</AlertTitle>
          { errorMessage } — <strong>check it again!</strong>
        </Alert>
        ) }
      </div>
      <div className={ Styles.Container }>
      <Modal open={ phoneModal } onClose={ handleClosePhoneModal }>
        <form onSubmit={ handleFindByPhone } className={ Styles.PhoneInputBox }>
          <div className={ Styles.FindByPhoneHint }>
            <span>Mohon masukkan nomor hp (whatsapp) yang menerima pemberitahuan notifikasi survey, <br /> dengan format: +62 <span >***********</span></span> <br /> <span>contoh: +62 <span>8123456789</span></span>
          </div>
          <TextField error={ findPhoneError } helperText={ findPhoneError } value={ phone } onChange={ handleChangeFindByPhone } InputProps={ { startAdornment: <InputAdornment position="start">+62</InputAdornment> } } label="Phone Number" variant="outlined" fullWidth/>
          <div className={ Styles.PhoneInputActionWrapper }>
            <LoadingButton loading={ findPhoneLoading } type="submit">
              Submit
            </LoadingButton>
          </div>
        </form>
      </Modal>
        <form className={ Styles.QuizForm } onSubmit={ handleSubmitQuiz }>
          { type === 'TKO' ? (
          <div className={ `${ Styles.FormDivider } ${ Styles.TKO }` } >
            <div className={ Styles.FormSubDivider }>
              <div className={ Styles.SubAreaTitle }>
                <span>I. Responden</span>
              </div>
              <div className={ `${ Styles.RespondenArea } ${ Styles.TKO }` }>
                <div className={ `${ Styles.FormControl }` }>
                  <TextField size="small" value={ tkoData.Nama } onChange={ handleChangeTkoData } name="Nama" label="Nama" required fullWidth/>
                </div>
                <div className={ `${ Styles.FormControl }` }>
                  <TextField disabled size="small" value={ tkoData.NamaPerusahaan } onChange={ handleChangeTkoData } name="NamaPerusahaan" label="Ditempatkan di Perusahaan" required fullWidth/>
                </div>
                <div className={ `${ Styles.FormControl }` }>
                  <TextField size="small" value={ tkoData.Jabatan } onChange={ handleChangeTkoData } name="Jabatan" label="Jabatan" required fullWidth/>
                </div>
                <div className={ `${ Styles.FormControl }` }>
                  <TextField size="small" value={ tkoData.Kota } onChange={ handleChangeTkoData } name="Kota" label="Kota" required fullWidth/>
                </div>
                <div className={ `${ Styles.FormControl }` }>
                  <TextField size="small" value={ tkoData.KerjaSejak } onChange={ handleChangeTkoData } name="KerjaSejak" label="Mulai bekerja" placeholder='mm/dd/yyyy' required fullWidth/>
                </div>
              </div>
              <div className={ Styles.SubAreaTitle }>
                <span>II. Status TKO</span>
              </div>
              <div className={ Styles.MultiOptions }>
                <div style={ { flex: 1 } }>
                  <LabeledCustomCheckbox onCheck={ () => setTkoData(prev => ({ ...prev, StatusTKO: 'PKWT' })) } checked={ tkoData.StatusTKO === 'PKWT' } label="PKWT"/>
                </div>
                <div style={ { flex: 1 } }>
                  <LabeledCustomCheckbox onCheck={ () => setTkoData(prev => ({ ...prev, StatusTKO: 'PKM' })) } checked={ tkoData.StatusTKO === 'PKM' } label="PKM"/>
                </div>
              </div>
            </div>
            <div className={ Styles.FormSubDivider }>
              <div className={ Styles.SubAreaTitle }>
                <span>III. Karakteristik Kualitas</span>
              </div>
              <Quiz.Container>
                <Quiz.Header titleOnly={ true } title="*Kualitas Layanan"/>
                <Quiz.Item value={ tkoData.Rekrutmen } name="Rekrutmen" setValue={ handleChangeTkoData } label="Rekrutmen"/>
                <Quiz.Item value={ tkoData.PenempatanKerja } name="PenempatanKerja" setValue={ handleChangeTkoData } label="Penempatan / Kontrak Kerja"/>
                <Quiz.Item value={ tkoData.PenggunaanBpjs } name="PenggunaanBpjs" setValue={ handleChangeTkoData } label="Penggunaan BPJS"/>
                <Quiz.Item value={ tkoData.PayrollGaji } name="PayrollGaji" setValue={ handleChangeTkoData } label="Slip payroll / pembayaran gaji"/>
                <Quiz.Item value={ tkoData.PerpanjanganKontrak } name="PerpanjanganKontrak" setValue={ handleChangeTkoData } label="Terminasi / perpanjangan kontrak"/>
                <Quiz.Header titleOnly={ true } title="*Dukungan Aplikasi/Teknologi Informasi (jika ada)"/>
                <Quiz.Item value={ tkoData.AplikasiMWS } name="AplikasiMWS" setValue={ handleChangeTkoData } label="Aplikasi layanan inti (MWS)"/>
                <Quiz.Item value={ tkoData.AplikasiPendukungPekerjaan } name="AplikasiPendukungPekerjaan" setValue={ handleChangeTkoData } label="Aplikasi pendukung pekerjaan"/>
                <Quiz.Item value={ tkoData.AplikasiEmployeeBenefit } name="AplikasiEmployeeBenefit" setValue={ handleChangeTkoData } label="Aplikasi employee benefit/loan"/>
                <Quiz.Header titleOnly={ true } title="*Kerjasama"/>
                <Quiz.Item value={ tkoData.ResponPenangananKeluhan } name="ResponPenangananKeluhan" setValue={ handleChangeTkoData } label="Respon penanganan keluhan"/>
                <Quiz.Item value={ tkoData.KemudahanKontakStafPIS } name="KemudahanKontakStafPIS" setValue={ handleChangeTkoData } label="Kemudahan menjangkau / mengontak Staf di PIS"/>
                <Quiz.HeaderExtra title="*Pungutan Biaya"/>
                <Quiz.PermataExtra alasanYa={ alasanYaPungutanBiaya } setAlasanYa={ setAlasanYaPungutanBiaya } value={ tkoData.AdakahPungutanBiaya } name="AdakahPungutanBiaya" setValue={ handleChangeTkoData } label="Apakah pernah diminta bayaran atas layanan di PIS"/>
                <Quiz.Item labelOnly label="Secara umum, bagaimana PIS dibandingkan perusahaan alih daya lainnya"/>
                <Quiz.Item outList value={ tkoData.PIS } name="PIS" setValue={ handleChangeTkoData } label="PT PERMATA INDO SEJAHTERA"/>
                <Quiz.Item outList fillProp value={ tkoData.PerusahaanLain.includes(': ') ? tkoData.PerusahaanLain.split(': ')[1] : tkoData.PerusahaanLain } name="PerusahaanLain" setValue={ handleChangeTkoData } placeholder={ 'Provider alih daya lainnya' }/>
              </Quiz.Container>
            </div>
          </div>
          ) : (
          <div className={ Styles.FormDivider }>
            <div className={ Styles.FormSubDivider }>
              <div className={ Styles.SubAreaTitle }>
                <span>I. Responden</span>
              </div>
              <div className={ `${ Styles.RespondenArea } ${ Styles.CC }` }>
                <div className={ `${ Styles.FormControl } ${ Styles.Small1 }` }>
                  <TextField disabled size="small" value={ ccData.NamaPerusahaan } onChange={ handleChangeCcData } name="NamaPerusahaan" label="Nama Perusahaan" required fullWidth/>
                </div>
                <div className={ `${ Styles.FormControl } ${ Styles.PhoneExt }` }>
                  <TextField className={ Styles.Phone } size="small" value={ ccData.TelponPerusahaan } onChange={ handleChangeCcData } name="TelponPerusahaan" InputProps={ { startAdornment: <InputAdornment position="start">+62</InputAdornment> } } label="Telepon Kantor" required fullWidth/>
                  <TextField className={ Styles.Ext } size="small" value={ ccData.Ekstensi } onChange={ handleChangeCcData } name="Ekstensi" label="Ext" required fullWidth/>
                </div>
                <div className={ `${ Styles.FormControl } ${ Styles.Small3 }` }>
                  <TextField size="small" value={ ccData.UnitKerja } onChange={ handleChangeCcData } name="UnitKerja" label="Unit Kerja" required fullWidth/>
                </div>
                <div className={ `${ Styles.FormControl } ${ Styles.Big }` }>
                  <TextField size="small" value={ ccData.Alamat } onChange={ handleChangeCcData } name="Alamat" label="Alamat Kantor" required fullWidth multiline rows={ 3.5 }/>
                </div>
                <div className={ `${ Styles.FormControl } ${ Styles.Small5 }` }>
                  <TextField size="small" label="Nama Responden" value={ ccData.Nama } onChange={ handleChangeCcData } name="NamaResponden" required fullWidth/>
                </div>
                <div className={ `${ Styles.FormControl } ${ Styles.Small6 }` }>
                  <TextField disabled size="small" value={ ccData.TelponHp } onChange={ handleChangeCcData } name="TelponHp" InputProps={ { startAdornment: <InputAdornment position="start">+62</InputAdornment> } } label="Telepon/HP" required fullWidth/>
                </div>
                <div className={ `${ Styles.FormControl } ${ Styles.Small7 }` }>
                  <TextField size="small" label="Jabatan" value={ ccData.Jabatan } onChange={ handleChangeCcData } name="Jabatan" required fullWidth/>
                </div>
                <div className={ `${ Styles.FormControl } ${ Styles.EndGrid }` }>
                  <div className={ `${ Styles.FormControl } ${ Styles.Small8 }` }>
                    <TextField size="small" value={ ccData.Email } onChange={ handleChangeCcData } name="Email" label="Email" required fullWidth/>
                  </div>
                  <div className={ `${ Styles.FormControl } ${ Styles.Small8 }` }>
                    { involvementSelect ? (
                    <TextField size="small" value={ ccData.AreaOfInvolvement } onChange={ handleChangeCcAreaInvolvement } name="AreaOfInvolvement" label="Area tanggung jawab" required fullWidth select>
                      <MenuItem value={ 'Purchasing/Vendor Mgt' }>Purchasing/Vendor Mgt</MenuItem>
                      <MenuItem value={ 'Recruitment' }>Recruitment</MenuItem>
                      <MenuItem value={ 'Performance Mgt' }>Performance Mgt</MenuItem>
                      <MenuItem value={ 'Payroll' }>Payroll</MenuItem>
                      <MenuItem value={ 'Invoice' }>Invoice</MenuItem>
                      <MenuItem value={ 'Other' }>Other</MenuItem>
                    </TextField>
                    ) : (
                      <div className={ Styles.AreaInvolvement }>
                        <TextField style={ { flex: 1 } } size="small" value={ ccData.AreaOfInvolvement } onChange={ handleChangeCcData } name="AreaOfInvolvement" label="Sebutkan" required fullWidth/>
                        <div onClick={ () => { setInvolvementSelect(true); setCcData(prev => ({ ...prev, AreaOfInvolvement: '' })) } } style={ { flex: 0.15 } }>
                          <Close/>
                        </div>
                      </div>
                    ) }
                  </div>
                </div>
              </div>
              <div className={ Styles.SubAreaTitle }>
                <span>II. Posisi PIS di Perusahaan</span>
              </div>
              <div className={ Styles.MultiOptions }>
                <div style={ { flex: 1 } }>
                  <LabeledCustomCheckbox onCheck={ () => setCcData(prev => ({ ...prev, PosisiPisDiPerusahaan: prev.PosisiPisDiPerusahaan === 'Single Vendor' ? '' : 'Single Vendor' })) } checked={ ccData.PosisiPisDiPerusahaan === 'Single Vendor' } label="Single Vendor"/>
                </div>
                <div style={ { flex: 1 } }>
                  <LabeledCustomCheckbox onCheck={ () => setCcData(prev => ({ ...prev, PosisiPisDiPerusahaan: prev.PosisiPisDiPerusahaan === 'Multi Vendor' ? '' : 'Multi Vendor' })) } checked={ ccData.PosisiPisDiPerusahaan === 'Multi Vendor' } label="Multi Vendor"/>
                </div>
              </div>
              <div className={ Styles.SubAreaTitle }>
                <span>III. Ruang lingkup jasa</span>
              </div>
              <div className={ Styles.MultiOptions }>
                <div style={ { flex: 1 } }>
                  <LabeledCustomCheckbox onCheck={ () => setCcData(prev => ({ ...prev, RuangLingkupJasa: prev.RuangLingkupJasa === 'MPO' ? '' : 'MPO' })) } checked={ ccData.RuangLingkupJasa === 'MPO' } label="MPO"/>
                </div>
                <div style={ { flex: 1 } }>
                  <LabeledCustomCheckbox onCheck={ () => setCcData(prev => ({ ...prev, RuangLingkupJasa: prev.RuangLingkupJasa === 'BPO' ? '' : 'BPO' })) } checked={ ccData.RuangLingkupJasa === 'BPO' } label="BPO"/>
                </div>
              </div>
            </div>
            <div className={ Styles.FormSubDivider }>
              <div className={ Styles.SubAreaTitle }>
                <span>IV. Karakteristik Kualitas</span>
              </div>
              <Quiz.Container>
                <Quiz.Header title="*Kualitas Layanan"/>
                <Quiz.Item value={ ccData.KualitasLayanan } name="KualitasLayanan" setValue={ handleChangeCcData } label="Kualitas layanan"/>
                <Quiz.Item value={ ccData.KualitasTKO } name="KualitasTKO" setValue={ handleChangeCcData } label="Kualitas TKO"/>
                <Quiz.Item value={ ccData.KetepatanWaktu } name="KetepatanWaktu" setValue={ handleChangeCcData } label="Ketepatan waktu fulfillment"/>
                <Quiz.Item value={ ccData.PemeliharaanHubKlien } name="PemeliharaanHubKlien" setValue={ handleChangeCcData } label="Membangun / Memelihara hubungan - Klien"/>
                <Quiz.Item value={ ccData.PemeliharaanHubTKO } name="PemeliharaanHubTKO" setValue={ handleChangeCcData } label="Membangun / Memelihara hubungan - TKO"/>
                <Quiz.Header titleOnly={ true } title="*Dukungan Aplikasi/Teknologi Informasi (jika ada)"/>
                <Quiz.Item value={ ccData.AplikasiMWS } name="AplikasiMWS" setValue={ handleChangeCcData } label="Aplikasi layanan inti (MWS)"/>
                <Quiz.Item value={ ccData.AplikasiPendukungPekerjaan } name="AplikasiPendukungPekerjaan" setValue={ handleChangeCcData } label="Aplikasi pendukung pekerjaan"/>
                <Quiz.Item value={ ccData.AplikasiEmployeeBenefit } name="AplikasiEmployeeBenefit" setValue={ handleChangeCcData } label="Aplikasi employee benefit/loan"/>
                <Quiz.Header title="*Kerjasama"/>
                <Quiz.Item value={ ccData.ResponPenangananKeluhan } name="ResponPenangananKeluhan" setValue={ handleChangeCcData } label="Respon penanganan keluhan"/>
                <Quiz.Item value={ ccData.KemudahanKontakStafPIS } name="KemudahanKontakStafPIS" setValue={ handleChangeCcData } label="Kemudahan menjangkau / mengontak Staf di PIS"/>
                <Quiz.Item labelOnly label="Secara umum, bagaimana PIS dibandingkan provider o/s lainnya"/>
                <Quiz.Item outList value={ ccData.PIS } name="PIS" setValue={ handleChangeCcData } label="PT PERMATA INDO SEJAHTERA"/>
                <Quiz.Item outList fillProp value={ ccData.PerusahaanLain.includes(': ') ? ccData.PerusahaanLain.split(': ')[1] : ccData.PerusahaanLain } name="PerusahaanLain" setValue={ handleChangeCcData } placeholder={ 'Provider o/s lainnya' }/>
              </Quiz.Container>
            </div>
          </div>
          ) }

          <div className={ Styles.FormBlockDivider }>
            <div className={ Styles.SubAreaTitle }>
                <span>V. Saran perbaikan untuk peningkatan kualitas pelayanan kami</span>
              </div>
            <div className={ Styles.AdviseWrapper }>
              <TextField size="small" label="Kotak saran" value={ type === 'TKO' ? tkoData.Saran : ccData.Saran } onChange={ type === 'TKO' ? handleChangeTkoData : handleChangeCcData } name="Saran" fullWidth multiline rows={ 5 }/>
            </div>
            <div className={ Styles.SubmitWrapper }>
              <ButtonLoader type="submit" className={ Styles.SubmitHandler }>
                <span>Submit</span>
              </ButtonLoader>
            </div>
          </div>
        </form>
      </div>
    </PermataQuizWrapper>
  )
}
