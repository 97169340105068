import React from 'react'
import Styles from './styles.module.scss'
import { Check } from '@mui/icons-material'

export default function LabeledCustomCheckbox(props) {
  const { label, checked, onCheck, disabled } = props

  const handleOnCheck = () => {
    if (!disabled) onCheck()
  }

  return (
    <div { ...props } className={ Styles.Wrapper }>
      <div onClick={ handleOnCheck } className={ disabled ? Styles.DisabledBox : Styles.Box }>
        { checked && (
        <Check/>
        ) }
      </div>
      <div className={ disabled ? Styles.DisabledLabel : Styles.Label } onClick={ handleOnCheck } >
        <span>{ label }</span>
      </div>
    </div>
  )
}
