import axios from '../../../axios'

export default class SurveyAction {
  static fetch = (access_token) => async (dispatch, getState) => {
    try {
      const { data } = await axios({
        method: 'GET',
        url: '/survey',
        headers: {
          access_token
        }
      })
      
      const { data: surveys, code } = data
      if (code === 200) {
        dispatch({
          type: 'SET_SURVEY',
          payload: surveys.filter(el => el.type === 'CC')
        })
        dispatch({
          type: 'SET_TKO_SURVEY',
          payload: surveys.filter(el => el.type === 'TKO')
        })
      }
    } catch(err) {
      console.log(err)
    }
  } 
}