import axios from '../../../axios'

export default class ProfileAction {
  static introduct = (access_token) => async (dispatch, getState) => {
    try {
      const { data: { data } } = await axios({
        method: 'GET',
        url: '/profile',
        headers: {
          access_token
        }
      })
      dispatch({
        type: 'SET_PROFILE',
        payload: {
          name: data.Name,
          nrk: data.Nrk
        }
      })
    } catch (err) {
      console.log(err, 'introduct err')
    }
  }
}