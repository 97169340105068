import axios from '../axios'

export const submitCcSurvey = (id, surveyData) => new Promise((resolve, reject) => {
  axios({
    method: 'POST',
    url: '/cc/' + id,
    data: surveyData
  }).then(({ data }) => {
    resolve(data)
  }).catch(err => {
    reject(err)
  })
})

export const submitTkoSurvey = (id, surveyData) => new Promise((resolve, reject) => {
  axios({
    method: 'POST',
    url: '/tko/' + id,
    data: surveyData
  }).then(({ data }) => {
    resolve(data)
  }).catch(err => {
    reject(err)
  })
})
