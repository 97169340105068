import Styles from './styles.module.scss'
import React, { useEffect, useState } from 'react'
import { Check } from '@mui/icons-material'
import { Rating } from '@mui/material'

const Quiz = {
  Container: (props) => {
    const { children } = props
    return (
      <ol className={ Styles.QuizOrderList }>
        { children }
      </ol>
    )
  },
  Header: (props) => {
    const { title } = props
    return (
      <div className={ `${ Styles.Wrapper } ${ Styles.Header }` }>
        <div className={ Styles.LabelWrapper }>
          { title }
        </div>
      </div>
    )
  },
  HeaderExtra: (props) => {
    const { title } = props
    return (
      <div className={ `${ Styles.Wrapper } ${ Styles.Header }` }>
        <div className={ Styles.LabelWrapper }>
          { title }
        </div>
      </div>
    )
  },
  PermataExtra: (props) => {
    const { outList, label, value, name, setValue, alasanYa, setAlasanYa } = props

    const handleClick = (choice) => {
      setValue({
        target: {
          name,
          value: choice
        }
      })
    }

    if (outList) {
      return (
        <div className={ Styles.Wrapper }>
          <div className={ Styles.LabelWrapper }>
            { label }
          </div>
          <div className={ Styles.ExtrasWrapper }>
            <div onClick={ () => handleClick('Ya') } className={ `${ Styles.Box } ${ Styles.Ya }` }>
              { value !== '' && value !== 'Tidak' && (
              <Check/>
              ) }
            </div>
            <div>
              <span>Ya</span>
            </div>
            <div onClick={ () => handleClick('Tidak') } className={ `${ Styles.Box } ${ Styles.Tidak }` }>
              { value === 'Tidak' && (
              <Check/>
              ) }
            </div>
            <div>
              <span>Tidak</span>
            </div>
            {/* { value !== '' && value !== 'Tidak' && (
            <div className={ Styles.Reason }>
              <input type="text" value={ alasanYa } onChange={ (e) => setAlasanYa(e.target.value) } placeholder={ 'Bila ya, untuk' }/>
            </div>
            ) } */}
          </div>
        </div>
      )
    }

    return (
      <li>
        <div className={ `${ Styles.Wrapper } ${ Styles.Extra }` }>
          <div className={ Styles.LabelWrapper }>
            { label }

            { value !== '' && value !== 'Tidak' && (
            <div className={ Styles.ExtraReasonWrapper }>
              <div>
                <input type="text" value={ alasanYa } onChange={ (e) => setAlasanYa(e.target.value) } placeholder={ 'Alasan' }/>
              </div>
            </div>
            ) }
          </div>
          <div className={ Styles.ExtrasWrapper }>
            <div className={ Styles.Ya }>
              <div onClick={ () => handleClick('Ya') } className={ Styles.Box }>
                { value !== '' && value !== 'Tidak' && (
                <Check/>
                ) }
              </div>
              <div>
                <span>Ya</span>
              </div>
            </div>
            <div className={ Styles.Tidak }>
              <div onClick={ () => handleClick('Tidak') } className={ Styles.Box }>
                { value === 'Tidak' && (
                <Check/>
                ) }
              </div>
              <div>
                <span>Tidak</span>
              </div>
            </div>
            {/* { value !== '' && value !== 'Tidak' && (
            <div>
              <div>
                <input type="text" value={ alasanYa } onChange={ (e) => setAlasanYa(e.target.value) } placeholder={ 'Bila ya, untuk' }/>
              </div>
            </div>
            ) } */}
          </div>
        </div>
      </li>
    )
  },
  Item: (props) => {
    const { label, value, setValue, name, outList, labelOnly, fillProp, placeholder } = props
    const [selfLabel, setSelfLabel] = useState('')
    const [rating, setRating] = useState(0)
    const [labelError, setLabelError] = useState(0)

    useEffect(() => {
      switch (value) {
        case 'Sangat Sesuai Harapan':
          setRating(5)
          break;
        case 'Sesuai Harapan':
          setRating(4)
          break;
        case 'Cukup Sesuai Harapan':
          setRating(3)
          break;
        case 'Kurang Sesuai Harapan':
          setRating(2)
          break;
        case 'Sangat Tidak Sesuai Harapan':
          setRating(1)
          break;
        default:
          setRating(0)
      }
    }, [value])

    const handleClick = (event, choice) => {
      let newValue;
      if (!fillProp || (fillProp && selfLabel)) {
        switch (choice) {
          case 5:
            newValue = 'Sangat Sesuai Harapan'
            break;
          case 4:
            newValue = 'Sesuai Harapan'
            break;
          case 3:
            newValue = 'Cukup Sesuai Harapan'
            break;
          case 2:
            newValue = 'Kurang Sesuai Harapan'
            break;
          case 1:
            newValue = 'Sangat Tidak Sesuai Harapan'
            break;
          default:
            newValue = null
        }
        setValue({
          target: {
            name,
            value: newValue,
            banding: selfLabel ? selfLabel : undefined
          }
        })
      } else {
        setLabelError(1)
      }
    }

    if (outList) {
      return (
        <div className={ Styles.Wrapper }>
          { fillProp ? (
          <div className={ Styles.LabelWrapper }>
            <label>{ placeholder }</label>
            <input style={ { borderColor: Boolean(labelError) ? 'red' : 'rgba(0, 0, 0, 0.3)' } } type="text" value={ selfLabel } onChange={ (e) => { setSelfLabel(e.target.value); setLabelError(0) } } placeholder={ "Masukkan disini" }/>
          </div>
          ) : (
          <div className={ Styles.LabelWrapper }>
            { label }
          </div>
          ) }
          { !labelOnly && (
          <div className={ Styles.RatingWrapper }>
            <Rating value={ rating } onChange={ handleClick }/>
          </div>
          ) }
        </div>
      )
    }

    return (
      <li>
        <div className={ Styles.Wrapper }>
          { fillProp ? (
          <div className={ Styles.LabelWrapper }>
            <input type="text" value={ selfLabel } onChange={ (e) => setSelfLabel(e.target.value) } placeholder={ placeholder }/>
          </div>
          ) : (
          <div className={ Styles.LabelWrapper }>
            { label }
          </div>
          ) }
          { !labelOnly && (
          <div className={ Styles.RatingWrapper }>
            <Rating value={ rating } onChange={ handleClick }/>
          </div>
          ) }
        </div>
      </li>
    )
  }
}

export default Quiz